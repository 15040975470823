.trendingNewsRightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.trendingNewsRightHeaderTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  gap: 24px;
}

.trendingNewsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--view-height);
  background: var(--background-primary);
  border-radius: 4px;
  gap: 8px;
  padding: 8px;
}

.stockTwitsTitle {
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  color: var(--white);
}

.rightSideWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.closeSidebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.rightSideWrapperClosed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.stocktwitsLogo {
  width: 28px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.trendingNewsRightRow {
  font-size: 13px;
}

.stocktwitsTrendingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stocktwitsTrendingWrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

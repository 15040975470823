.newsTitle {
  font-size: 13px;
  color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
}

.newsText {
  font-size: 13px;
  color: #aaaaaa;
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
}

.NewsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.newsTitle1 {
  font-size: 13px;
  color: var(--white);
  margin-left: 20px;
  line-height: 24px;
}

.thingig {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white);
}

.swap {
  width: 20px;
  height: 20px;
}

.swapWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 28px;
  border-radius: 4px;
  height: 28px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.errorBoundaryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 80px;
  background: var(--outseek-background);
}

.errorBoundaryInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.errorBoundaryInfoRefresh {
  cursor: pointer;
  color: var(--primary-color);
  padding: 0 5px;
  &:hover {
    text-decoration: underline;
  }
}

.errorBoundaryInfoText {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
}

.errorBoundaryInfoTitle {
  font-size: 48px;
  font-weight: 600;
  color: var(--white);
}

.errorBoundaryImage {
  margin-left: -250px;
  user-select: none;
}

@media (max-width: 1000px) {
  .errorBoundaryImage {
    margin-left: 0;
  }
  .errorBoundaryContainer {
    padding: 40px;
  }
  .errorBoundaryInfo {
    flex-direction: column;
    gap: 20px;
  }
  .errorBoundaryInfoText {
    text-align: center;
  }
  .errorBoundaryInfoTitle {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .errorBoundaryImage {
    margin-left: 0;
    display: none;
  }
  .errorBoundaryContainer {
    padding: 30px;
  }
  .errorBoundaryInfoTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .errorBoundaryInfoText {
    text-align: center;
    font-size: 18px;
  }
  .errorBoundaryInfoTitle {
    text-align: center;
    font-size: 26px;
  }
}

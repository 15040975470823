.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  width: 100%;
  gap: 8px;
  padding: 8px;
  border: 1px solid var(--grey-accent);
  border-top: none;
  border-radius: 4px;
}

.messagesContainer {
  flex-grow: 1;
  overflow-y: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 232px);
}

.message {
  margin: 4px 0px;
  border-radius: 4px;
  max-width: 80%;
  padding: 6px 8px;
  font-size: var(--default-text);
}

.userMessage {
  align-self: flex-end;
  background-color: var(--primary-color);
}

.botMessage {
  align-self: flex-start;
  background-color: var(--background-secondary);
}

.messageForm {
  display: flex;
  padding: 6px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  gap: 6px;
}

.textarea {
  flex-grow: 1;
  background-color: var(--background-secondary);
  font-size: var(--default-text);
  border: none;
  outline: none;
}

.sendButton {
  background-color: var(--background-tertiary);
  border: none;
  border-radius: 4px;
}

.waitingText {
  text-align: center;
}

.comparisonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 6px 6px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  width: 100%;
  font-size: var(--default-text);

  &:hover {
    background-color: var(--background-secondary);
  }
}

.metricName {
  display: flex;
  gap: 8px;
  align-items: center;
}

.metricSelect {
  height: 14px;
  width: 14px;
  background-color: #bbb;
  border-radius: 3px;
  display: inline-block;
}

.borderline {
  width: calc(100% - 8px);
  height: 2px;
  background-color: var(--background-secondary);
  border-radius: 8px;
}

.otherMetrics {
  display: flex;
  gap: 8px;
}

.metricBarWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.dataWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
}

.comparisonTable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--white);
  font-size: var(--default-text);
}

.categoryHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-bottom: 2px solid var(--background-secondary);
  padding: 6px 8px;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--bold-text);
}

.metricContainer {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}

.metricContainerClosed {
  max-height: 0;
}

.listWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.searchMetricsInput {
  width: 100%;
  height: 28px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.watchlistStatistics {
  width: 100%;
  overflow-x: auto;
}

.statTable {
  width: 100%;
}

.statTableRow {
  border-bottom: 2px solid var(--background-secondary);

  &:hover {
    background-color: var(--background-secondary);
  }
}

.statTableData {
  color: var(--white);
  font-size: var(--default-text);
  font-family: var(--font-family);
  height: 32px;
  line-height: 28px;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.statsTableHead {
  top: 0;
  position: sticky;
  white-space: nowrap;
  height: 28px;
}

.statTableHeader {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  // padding: 8px 8px 0 4px;
  font-weight: 500;
  font-size: 13px;
  height: 24px;
  background-color: var(--background-primary);
  z-index: 1000;
  color: var(--white);
  cursor: pointer;
}

.compareStats {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.compareStatsItem {
  width: 100%;
  height: 100%;
  border: 2px solid var(--background-secondary);
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--large-text);
}

.statValues {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.compareStatsItems {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}

.compareStatsItemSymbol {
  white-space: nowrap;
}

.statHeader {
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.compareStatsItemStat {
  white-space: nowrap;
}

.worldIndexChart {
  height: 100%;
  width: 100%;
}

.chartWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 2px;
}

.indexChartTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: fit-content;
}

.header {
  display: flex;
  align-items: center;
  height: 34px;
}

.toggleButton {
  background-color: var(--background-tertiary);
  border: none;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  color: var(--off-white);
  transition: background-color 0.1s ease;
}

.toggleButton:hover {
  background-color: var(--background-secondary);
}

.sidebar {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  transition: width 0.15s ease;
  overflow: hidden;

  &.open {
    width: 240px;
  }

  &.closed {
    width: 60px;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.closeButton:hover {
  background-color: var(--background-tertiary);
}

.sidebar ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sidebar ul li {
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.1s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:hover {
    background-color: var(--background-tertiary);

    .chatActions {
      display: flex;
    }
  }

  &.chatCardClosed {
    justify-content: center;

    .chatName {
      position: relative;
      max-width: 40px;
      white-space: nowrap;
      overflow: hidden;
    }

    .chatName::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 20px;
      background: linear-gradient(
        to left,
        var(--background-secondary),
        transparent
      );
    }

    .chatActions {
      display: none;
    }

    &:hover {
      .chatName::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 20px;
        background: linear-gradient(
          to left,
          var(--background-tertiary),
          transparent
        );
      }
    }
  }
}

.chatName {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  // text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 8px;
  font-size: 14.5px;
}

.chatActions {
  display: none;
  gap: 8px;
}

.searchInput {
  width: 100%;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-secondary);
  color: var(--light-grey-accent);
  font-size: var(--large-text);
  padding: 6px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.21 ease;
  }

  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.1s ease;
  }
}

.chatCard {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  color: var(--light-grey-accent);

  &:hover {
    background-color: var(--background-tertiary) !important;
  }
}

.displayChats {
  height: calc(100% - 74px);
  overflow-y: auto;
}

.divider {
  font-size: var(--default-text);
  font-weight: 600;
  color: grey;
  padding-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 2px;
}

.chatTitle {
  font-weight: var(--bold-text);
}

.modalOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--light-grey-accent);
  width: 600px;
  background-color: var(--background-primary);
  border: 2px solid var(--background-tertiary);
  padding: 16px;
  border-radius: 4px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: var(--bold-text);
  color: var(--white);
  font-size: 18px;
}

.link {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: underline !important;
}

.closeButton {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--background-tertiary);
  }
}

.companyOverviewDividendBarChartWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
}

.companyOverviewAnalyst {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.companyOverviewAnalystTitleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 21px;
}

.title {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
  font-weight: var(--bold-text);
  display: flex;
  gap: 4px;
  margin-top: 1px;
}

.noData {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
}

.noDataContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartWrapper {
  width: 100%;
  height: calc(100% - 21px);
}

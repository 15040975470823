.rosterStats {
  display: flex;
  height: 100%;
  width: 100%;
  font-size: var(--default-text);
  gap: 8px;
}

.statsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
}

.statsTitle {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  white-space: nowrap;
  height: 28px;
  display: flex;
  align-items: center;
}

.statsHeader {
  display: flex;
  flex-direction: column;
}

.dataItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--background-secondary);
  padding: 4px 6px;
}

.dataTitle {
  font-size: var(--default-text);
  color: var(--off-white);
  white-space: nowrap;
}

.dataValue {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  white-space: nowrap;
}

.chartWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chartTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
  width: fit-content;
}

.dataFrequencyButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.divider {
  width: 1px;
  height: 60%;
  background-color: var(--grey-accent);
}

.chartHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chartTitle {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.labelValue {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: var(--default-text);
  font-weight: 500;
  color: var(--light-grey-accent);
  white-space: nowrap;
}

.value {
  font-size: 18px;
  font-weight: var(--bold-text);
  color: var(--white);
  white-space: nowrap;
}

.labelValueGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  width: 100%;
  gap: 16px;
}

.divider {
  height: 34px;
  width: 1px;
  background-color: var(--grey-accent);
}

.ETFInfoWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
}

.descriptionTableWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.keyValueWrapper {
  width: fit-content;
  min-width: 500px;
}

.descriptionWrapper {
  // display: flex;
  // flex-direction: column;
  font-size: var(--large-text);
  font-weight: 500;
}

.desc {
  font-size: var(--large-text);
  height: 98px;
  overflow-y: auto;
}

.fundWebsite {
  color: var(--primary-color);
}

.infoDivider {
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: var(--background-tertiary);
}

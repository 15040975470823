.scrollContainer {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  white-space: nowrap;
}

.scrollContainerWrapper {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  white-space: nowrap;
  align-items: center;
  width: 100%;
}

.arrowButton {
  background: transparent !important;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px;
  margin: 0 4px;
  height: 40px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
  border: none;
  outline: none;
  color: var(--white);
  border: 2px solid transparent;

  &:hover {
    border: 2px solid var(--primary-color);
  }
}

.indexWrapper {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
  gap: 8px;
}

.globeWrapper {
  height: 100%;
  width: 100%;
}

.topWrapper {
  display: flex;
  gap: 8px;
  height: calc(100% - 268px);
}

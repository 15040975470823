.screenerTabList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  scrollbar-width: thin;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--grey-accent);
}

.screenerTabListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  background: var(--background-primary);
  z-index: 100;
}

.metricCatalogContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  z-index: 100;
  background: var(--background-primary);
  border-radius: var(--border-radius);
  gap: 6px;
}

.metricCatalogCloseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 0px;
  color: var(--white);
  width: 100%;
  height: 30px;
  cursor: pointer;
  // background-color: var(--primary-color);
  background-color: var(--grey-accent);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  user-select: none;
}

.icon {
  width: 24px;
  height: 24px;
}

.closeText {
  font-size: var(--large-text);
  color: var(--white);
}

.metricCatalogSearch {
  margin: 4px;
  width: calc(100% - 8px);
  height: 28px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.catalogTab {
  height: 28px;
  width: fit-content;
  white-space: nowrap;
  padding: 0 8px;
  color: var(--off-white);
  list-style-type: none;
  cursor: pointer;
  font-size: var(--default-text);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-primary) !important;
  border: none !important;
  white-space: nowrap;
}

.metricListWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.tabDivider {
  height: 20px;
  width: 1px;
  background-color: var(--grey-accent);
}

.tabPanel {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 270px);
}

.metricTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: 1px solid var(--grey-accent);
  border-top: none;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.filterPillContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  overflow: auto;
  height: 28px;
}

.closeAndCatalogButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  z-index: 100;
  background: var(--background-primary);
  border-radius: var(--border-radius);
}

.aiScreenerButton {
  padding: 8px;
  width: 100%;
  height: fit-content;
  color: white;
}

.geoRevTableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  color: var(--white);
  font-size: var(--default-text);
  width: 100%;
  height: 24px;
  background: var(--background-primary);
  border-bottom: 2px solid var(--background-secondary);
}

.geoRevTableLabelCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  width: fit-content;
  height: 24px;
}

.geoRevTableValueCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  gap: 6px;
}

.rangeMeterBar {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
}

.rangeMeterLabels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 100%;
}

.rangeMeterMinMax {
  font-size: 11px;
  color: var(--light-grey-accent);
}

.rangeMeterProgressWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 14px;
}

.rangeMeterValue {
  font-size: 11px;
  color: var(--light-grey-accent);
  position: absolute;
  transform: translateX(-50%);
  top: 2px;
}

.rangeMeterValueProgress {
  position: relative;
  flex-grow: 1;
  align-items: top;
  display: flex;
}

.progressBackground {
  height: 10px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(248, 79, 49, 0.6),
    rgba(33, 173, 61, 0.6)
  );
  display: flex;
  border-radius: 4px;
  align-items: center;
}

.progressBar {
  height: 120%;
  background-color: none;
  border-right: 4px solid var(--light-grey-accent);
}

.rangeMeterLabel {
  font-size: var(--default-text);
  color: var(--white);
}

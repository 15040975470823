.descContainer {
  width: 600px;
  background-color: var(--background-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.topContainer {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.descText {
  font-size: var(--default-text);
  color: #ee1111;
}

.titleDesc {
  font-size: var(--default-text);
}

.bottomContainer {
  display: flex;
  flex-direction: row;
}

.descParagraph {
  text-align: left;
}

.leftPaneDesc {
  margin: 0px 5px 5px 5px;
}

.leftPaneItem {
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  margin-bottom: 5px;
  margin-bottom: 5px;
  color: var(--white);
}

.rightPaneDesc {
  margin: 0px 5px 5px 15px;
  font-size: var(--default-text);
}

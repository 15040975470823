.threeBenefitsWrapper {
  width: 100%;
  max-width: 1600px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.threeBenefitsColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: fit-content;
  width: 100%;
  // border: 1px solid rgba(225, 225, 225, 0.12);
  // padding: 20px;
}

.threeBenefitsContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: fit-content;
  width: 100%;
}

.threeBenefitsTitle {
  font-size: 38px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  padding: 0 20px;
}

.threeBenefitsSubtitle {
  font-size: 18px;
  color: var(--light-grey-accent);
  text-align: center;
  padding: 0 20px;
  width: 380px;
}

.benefitsImage {
  width: 90%;
  height: 90%;
  padding: 0 100px;
  padding-bottom: 0;
}

///// media shit

/// Media Queries

@media screen and (min-width: 1080px) and (max-width: 1399px) {
  .benefitsImage {
    width: 50%;
    height: 50%;
    padding: 0;
  }
  .threeBenefitsSubtitle {
    font-size: 16px;
    width: 100%;
  }
  .threeBenefitsTitle {
    font-size: 32px;
  }
}

@media screen and (min-width: 621px) and (max-width: 1079px) {
  .threeBenefitsWrapper {
    width: 100%;
    height: 100%;
    gap: 20px;
  }
  .threeBenefitsColumn {
    gap: 20px;
    width: 100%;
  }

  .threeBenefitsContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .benefitsImage {
    width: 150px;
    height: 150px;
    padding: 0;
  }
  .threeBenefitsSubtitle {
    font-size: 16px;
    text-align: left;
    padding: 0 20px;
    width: 100%;
  }
  .threeBenefitsTitle {
    font-size: 32px;
    color: var(--white);
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    padding: 0 20px;
  }
}

@media screen and (max-width: 620px) {
  .threeBenefitsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .threeBenefitsColumn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: fit-content;
    width: 100%;
  }
  .threeBenefitsContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .benefitsImage {
    width: 100px;
    height: 100px;
    padding: 0;
  }
  .threeBenefitsSubtitle {
    font-size: 14px;
    text-align: left;
    width: 100%;
    padding: 0;
  }
  .threeBenefitsTitle {
    font-size: 24px;
    color: var(--white);
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    padding: 0;
  }
}

.scaleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rectScale {
  fill: var(--background-secondary);
  stroke: var(--background-tertiary);
  stroke-width: 2;
}

.textMarker {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: default;
}

.lineMarker {
  stroke-dasharray: 0, 100;
  transition: stroke-dasharray 0.5s ease-in-out;
}

$FullBlockPadding: 60px;
$HalfBlockPadding: 30px;
$FortyGap: 40px;
$TwentyGap: 20px;

/////// BODY ///////
body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

/////// WRAPPER ///////

.tablet-view-homepage-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--outseek-background);
  overflow: hidden;
}

/////// SPACER ///////

.tablet-view-homepage-spacer {
  width: 100%;
  height: 100vh;
}

.tablet-view-homepage-half-spacer {
  width: 100%;
  height: 50vh;
}

/////// CONTENT ONE ///////

.tablet-view-homepage-content-1 {
  width: 100vw;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  // justify-content: center ;
  align-items: center;
  padding: 0 $FullBlockPadding;
  padding-top: 150px;
  gap: 30px;
  z-index: 1000;
}

.tablet-view-homepage-content-1-dashboard-img-top {
  width: 100%;
  height: 100%;
  z-index: 100;
  // margin-left: 10%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.tablet-view-homepage-content-1-col-2 {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 60px;
}

.tablet-view-homepage-content-1-title-bar {
  width: 10px;
  height: 55%;
  border-radius: 4px;
  background-color: var(--outseek-background);
  position: absolute;
  left: -35px;
  top: 8px;
}

.tablet-view-homepage-content-1-enter-terminal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tablet-view-homepage-content-1-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}

.tablet-view-homepage-content-1-col-1 {
  width: 40%;
  position: relative;
}

.tablet-view-homepage-content-1-col {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tablet-view-homepage-content-1-content-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tablet-view-homepage-content-1-content-row-2 {
  z-index: 100;
}

.tablet-view-homepage-content-1-title-font {
  user-select: none;
  font-size: 56px;
  color: var(--white);
  font-weight: var(--bold-text);
  line-height: 1;
  letter-spacing: -1px;
  text-shadow: 0 0 20px rgb(192 219 255 / 30%), 0 0 0px rgb(65 120 255 / 20%);
  color: var(--white);
  pointer-events: none;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  padding: 0 5%;
}

.tablet-view-homepage-content-1-description-font {
  font-size: 20px;
  line-height: 28px;
  // height: 28px;
  font-weight: 500;
  color: var(--white);
  width: 100%;
  user-select: none;
  text-align: center;
  margin-bottom: 12px;
}

/////// CONTENT TWO ///////

.tablet-view-homepage-content-2 {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $FortyGap;
  padding-bottom: 0;
  padding-top: 80px;
}

.tablet-view-homepage-content-2-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.tablet-view-homepage-content-2-col-1-title {
  font-size: 38px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tablet-view-homepage-content-2-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px;
}

.tablet-view-homepage-content-2-col-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.tablet-view-homepage-content-2-img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px 0px white;
  border-radius: 8px;
}
.tablet-view-homepage-content-2-img-2 {
  width: 100%;
  height: 100%;
}

/////// CONTENT TWO ///////

.tablet-view-homepage-content-3 {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $FortyGap;
  padding-bottom: 0;
  padding-top: 80px;
}

.tablet-view-homepage-content-3-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.tablet-view-homepage-content-3-col-1-title {
  font-size: 38px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablet-view-homepage-content-3-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10%;
}

.tablet-view-homepage-content-3-col-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.tablet-view-homepage-content-3-img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px 0px white;
  border-radius: 8px;
}

/////// CONTENT FOUR ///////

.tablet-view-homepage-content-4 {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $FortyGap;
  padding-bottom: 0;
  padding-top: 80px;
}

.tablet-view-homepage-content-4-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.tablet-view-homepage-content-4-col-1-title {
  font-size: 38px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablet-view-homepage-content-4-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  // font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px;
}

.tablet-view-homepage-content-4-col-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.tablet-view-homepage-content-4-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 6px 0px #606060;
  border: 1.5px solid #606060;
  margin-bottom: 4%;
}

/////// CONTENT SIX ///////

.tablet-view-homepage-content-6 {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  gap: 40px;
}

.tablet-view-homepage-content-6-col-title-2 {
  font-size: 32px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  text-align: center;
  padding: 0 20px;
  width: 100%;
}

/////// Panel Two ///////

.tablet-view-homepage-panel-two {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--white);
  position: relative;
  box-sizing: border-box;
  background-color: var(--outseek-background);
  padding: 0 $FullBlockPadding;
  padding-bottom: 0;
  padding-top: 80px;
}

.tablet-view-homepage-panel-two-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.tablet-view-homepage-panel-two-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 3800px;
  gap: 80px;
}

/////

@media screen and (max-width: 460px) {
  .tablet-view-homepage-panel-two-content-wrapper {
    gap: 30px;
    height: 3550px;
  }
  .tablet-view-homepage-panel-two {
    padding: 30px;
  }
  .tablet-view-homepage-content-6-col-title-2 {
    padding: 0;
    font-size: 28px;
  }

  /// first page
  .tablet-view-homepage-content-1 {
    width: 100vw;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 35px;
    padding-top: 100px;
    gap: 20px;
    z-index: 1000;
  }
  .tablet-view-homepage-content-1-content-row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tablet-view-homepage-content-1-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .tablet-view-homepage-content-1-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
  }
  .tablet-view-homepage-content-1-title-font {
    user-select: none;
    font-size: 42px;
    color: var(--white);
    font-weight: var(--bold-text);
    line-height: 1;
    color: var(--white);
    pointer-events: none;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .tablet-view-homepage-content-1-description-font {
    font-size: 18px;
    line-height: 20px;
    height: fit-content;
    font-weight: 500;
    color: var(--white);
    width: 100%;
    user-select: none;
    text-align: center;

    margin-bottom: 0;
  }
  .tablet-view-homepage-content-1-enter-terminal {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .tablet-view-homepage-content-1-dashboard-img-top {
    width: 100%;
    height: 100%;
    z-index: 100;
    // border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  ////
  .tablet-view-homepage-content-4-col-1-subtitle {
    padding: 0;
    font-size: 14px;
  }
  .tablet-view-homepage-content-4-col-1-title {
    padding: 0;
    font-size: 24px;
  }

  ///
  .tablet-view-homepage-content-4 {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    gap: 30px;
    padding-bottom: 0;
  }
}

.Standard-view-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: calc(100vh - 62px);
}

.chart-label-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-label {
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 24px;
  text-align: center;
}

.chart-wrapper {
  width: 100%;
}

.TimeRange-range-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.TimeRange-timeframe {
  font-style: normal;
  font-weight: var(--bold-text);
  font-size: 12px;
  white-space: nowrap;
  color: var(--white);
}

.TimeRange-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  border-radius: 4px;
}

.interval-continer-select {
  width: 26px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-primary);

  &:hover {
    background: var(--button-hover);
    transition: var(--button-transition);
  }
}

.interval-container {
  font-style: normal;
  font-weight: var(--bold-text);
  font-size: 12px;
  color: var(--white);
}

.BasicInfoWidget-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  color: var(--white);
  line-height: 24px;
  justify-content: space-between;
  width: 100%;
}

.BasicInfoWidget-label {
  // font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
}

.BasicInfoWidget-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  font-size: var(--default-text);
}

.BasicInfoWidget-container-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  border-radius: 4px;
}

.BasicInfoWidget-label-vertical {
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 24px;
}

.BasicInfoWidget-value-vertical {
  font-size: 14px;
  color: var(--white);
}

.stocktwits-title {
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 24px;
}

.SentimentTableWidget-title {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 0px 4px 0px 4px;
}

.label-container-sentiment {
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 24px;
}

.SentimentTableWidget {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
}

.SentimentTableWidget-table {
  max-height: calc((100vh - 224px) / 4);
}

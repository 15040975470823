.ratios {
  display: flex;
  flex-direction: column;
  height: var(--view-height);
  width: 100%;
  border-radius: 4px;
  color: var(--white);
  gap: 8px;
}

.ratiosContent {
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

// Tabs

.ratioTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ratioTabList {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 32px;
  overflow-y: hidden;
  overflow-x: auto;
}

.ratioTab {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  cursor: pointer;
  font-size: var(--default-text);
  user-select: none;
  white-space: nowrap;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.ratioSelectedTab {
  background-color: var(--grey-accent);
}

.ratioTabPanel {
  width: 100%;
}
.chartBox {
  width: 100%;
  height: 200px;
}

.tabPanelChild {
  height: 100%;
  width: 100%;
}

.divider {
  height: 20px;
  width: 1px;
  background-color: var(--grey-accent);
}

.selectedDivider {
  height: 20px;
  width: 1px;
  background-color: transparent;
}

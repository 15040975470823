.modal {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 650px;
  height: 450px;
  background-color: var(--background-primary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
  color: var(--white);
}

.savedScreensWrapper {
  display: flex;
  gap: 8px;
}

.selectedScreen {
  display: flex;
  gap: 8px;
  font-size: var(--large-text);
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: var(--bold-text);
}

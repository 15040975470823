.catalogContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  background: var(--background-primary);
}

.closeIcon {
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius);
}

.closeIcon:hover {
  background-color: rgba(215, 215, 215, 0.15);
}

.itemCatalogToolBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: fit-content;
}

.closeDiv {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: fit-content;
}

.itemCatalogTitle {
  font-size: 17.19px;
  margin: 0;
  font-weight: 400;
  color: var(--white);
}

.itemFilter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 420px;
  height: fit-content;
}

.itemContainer {
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  height: fit-content;
  gap: 16px;
}

.catalog {
  width: 100%;
  height: 65vh;
  max-width: 100vw;
}

.stockViewWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: var(--content-view-height);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
}

.standardViewContainer {
  display: flex;
  flex-direction: column;
  height: var(--view-height);
  gap: 8px;
}

.stockViewPanelTimerangeWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--background-primary);
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}

.coreBarWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--background-primary);
  border-radius: 4px;
  width: 45%;
  height: 100%;
}

.tableKeyWrapper {
  width: 55%;
  height: 100%;
}

.coreBarWrapperItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2px;
  height: 100%;
}

.coreBarWrapperLabel {
  font-size: var(--default-text);
  color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stockViewRight {
  width: 100%;
}

.stockViewPanel1 {
  gap: 8px;
  display: flex;
}

.stockViewPanel1Right {
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc((100vh - 110px) / 1.5);
  border-radius: 4px;
}

.stockViewPanel2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stockViewPanel3 {
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-primary);
}

.stockViewPanel3Label {
  padding: 0px 12px 0px 12px;
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
}

.stockViewPanel2ChartWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.top50Info {
  height: fit-content;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--background-primary);
  width: 100%;
}

.top50InfoChart {
  height: 100%;
  border-radius: 4px;
  background-color: var(--background-primary);
}

.top50InfoLabelItem {
  font-size: var(--large-text);
}

.top50InfoDataItem {
  font-size: var(--large-text);
}

.top50InfoLabel {
  display: flex;
  justify-content: space-between;
  gap: 200px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

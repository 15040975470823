.coreButton {
  border-radius: 4px;
  font-size: var(--default-text);
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  color: var(--white);
  gap: 4px;

  &:hover {
    opacity: 0.8;
    color: var(--white);
  }
}

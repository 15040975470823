.quoteBar {
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 4px;
  padding-right: 8px;
}

.loadingContainer {
  height: 48px !important;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 18px 12px;
}

.content1Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.content1Wrapper::-webkit-scrollbar {
  display: none;
}

.arrowButton {
  background: transparent !important;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px;
  height: 30px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
  border: none;
  outline: none;
  color: var(--white);

  &:hover {
    border: 2px solid var(--primary-color);
  }
}

.content2Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.quoteBarFlag {
  height: 38px;
  width: 38px;
}

.quoteBarPrice {
  color: var(--white);
  font-size: 20px;
  line-height: 22px;
  white-space: nowrap;
  width: fit-content;
  border-radius: 4px;
}

.divider {
  height: 22px;
  width: 1px;
  background-color: var(--grey-accent);
}

.quoteBarTimeStamp {
  color: var(--light-grey-accent);
  font-size: 12px;
  white-space: nowrap;
  width: 234px;
}

.quoteBarItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.dividerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 8px;
}

.quoteBarItemWrapperStockPrice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.quoteBarChange {
  font-size: 14px;
  line-height: 13px;
  white-space: nowrap;
  padding-left: 4px;
}

.quoteBarItemLabel {
  font-size: 12px;
  line-height: 13px;
  white-space: nowrap;
  color: var(--light-grey-accent);
}

.quoteBarItemValue {
  color: var(--white);
  font-size: var(--large-text);
  line-height: 22px;
  white-space: nowrap;
}

@keyframes flashGreen {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--green);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes flashRed {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--red);
  }
  100% {
    background-color: transparent;
  }
}

.up {
  animation: flashGreen 1s ease-in-out;
}

.down {
  animation: flashRed 1s ease-in-out;
}

.quoteBarCompanyLogo {
  height: 34px;
  width: 34px;
  margin-left: 4px;
}

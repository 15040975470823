.message {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}

.userMessage {
  background-color: var(--background-tertiary);
  align-self: flex-end;
}

.botMessage {
  background-color: var(--background-secondary);
  align-self: flex-start;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  height: 26px;
  color: var(--light-grey-accent);
  .userHeader {
    color: var(--light-grey-accent);
  }
  .botHeader {
    color: var(--primary-color);
    font-size: 14px;
  }
}

.timestamp {
  font-size: 12px;
}

.text {
  font-size: 15px;
  padding-bottom: 12px;
  p {
    margin-bottom: 0;
  }
}

.infoBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.copyButton {
  display: flex;
}

.sendButton {
  border: none;
  border-radius: 4px;
  padding: 4px;
  background-color: transparent;
}

.tableContainer {
  overflow-x: auto;
}

.companyOverviewChartTimeWrapper {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.timeRange {
  position: absolute;
  margin-top: 12px;
  z-index: 10;
}

.utilsButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.utilsButtons {
  display: flex;
  gap: 4px;
}

.navigate {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  color: var(--light-grey-accent);
  padding: 4px;
  font-size: var(--large-text);

  .navigateText {
    padding-top: 2px;
  }
}

.configuredComponent {
  padding-bottom: 8px;
  max-height: 500px;
  border-radius: 4px;
}

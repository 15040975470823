.subModalContainer {
  width: 320px;
  height: fit-content;
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  padding: 12px;
  gap: 12px;
}

.subModalTitle {
  font-style: normal;
  font-weight: var(--bold-text);
  font-size: 17.19px;
  color: var(--white);
  margin: 0;
}

.subModalTitlePanel {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.subModalPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 8px;
  width: 100%;
  height: 28px;
  background: var(--background-secondary);
  // box-shadow: inset 4px 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -1px 3px -1px #000000;
  border-radius: var(--border-radius);
  border: transparent;
  color: var(--white);
}
.subModalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  width: fit-content;
  height: 28px;
  border-radius: var(--border-radius);
  border: transparent;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  color: var(--white);
  background-color: transparent;

  &:hover {
    transition: all 0.2s ease-in;
    opacity: 80%;
    color: var(--white);
  }

  &:active {
    color: var(--white);
  }

  &:focus {
    color: var(--white);
  }
}

.subModalBottomPanel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;
  height: 28px;
}

.subModalInputPanel {
  width: 100%;
  height: 28px;
  border: 3px solid var(--gray-accent);
}

.modalCloseButton:hover {
  cursor: pointer;
  background-color: rgba(215, 215, 215, 0.15);
}

.modalCloseButton {
  height: 24px;
  width: 24px;
  border-radius: var(--border-radius);
}

.roadmapContainer {
  padding: 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--outseek-background);
}

.headerSection {
  text-align: center;
}

.mainHeader {
  font-size: 48px;
  color: var(--white);
}

.subHeader {
  font-size: 18px;
  color: var(--light-grey-accent);
}

.roadmap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roadmapSection {
  width: 80%;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: var(--item-background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-grey-accent);
  font-size: 22px;
  margin-bottom: 10px;
}

.title {
  font-weight: var(--bold-text);
  color: var(--white);
}

.date {
  font-size: 18px;
  color: var(--secondary-color);
}

.description {
  font-size: 16px;
  color: var(--light-grey-accent);
  margin-bottom: 20px;
}

.categoryTitle {
  font-size: 20px;
  color: var(--white);
  font-weight: var(--bold-text);
}

.roadmapDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  .categoryWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(50% - 8px);
  }

  li {
    font-size: 16px;
    color: var(--light-grey-accent);
  }
}

@media (max-width: 1100px) {
  .roadmapContainer {
    padding: 120px 20px;
  }
  .roadmapSection {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .categoryWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100% !important;
  }

  .roadmapDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
  }

  .mainHeader {
    font-size: 36px;
  }
}

@media (max-width: 450px) {
  .roadmapContainer {
  }
}

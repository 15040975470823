$header-height: 24px;

.card {
  border-radius: 4px;
  border: 1px solid var(--background-tertiary);
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-tertiary);
  border-radius: 4px 4px 0 0;
  padding: 0 8px;
  height: $header-height;
  user-select: none;
}

.cardLabel {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent {
  padding: 8px;
  height: calc(100% - $header-height);
  width: 100%;
}

.icon {
  margin-bottom: 4px;
  cursor: pointer;
}

.endButtonWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.etfWrapper {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.etfViewWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  height: var(--content-view-height);
  color: var(--white);
  gap: 8px;
}

.chartTimeWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.industryChartWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.timeRange {
  position: absolute;
  margin-top: 12px;
  z-index: 10;
}

.visualizations {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 212px);
  gap: 8px;
}

.bottomWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: calc(50% - 4px);
  height: 100%;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(50% - 4px);
  height: 100%;
}

.tableWrapper {
  width: 250px;
  height: 100%;
}

.chartWrapper {
  width: calc(100% - 250px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.motETFWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  height: var(--content-view-height);
  color: var(--white);
  gap: 8px;
}

.popularETFsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.popularETFs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  // width: 100%;
  gap: 6px;
}

.tickerCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.chartWidgetContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chartWidgetTitleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 28px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  padding: 0 8px;
}

.chartWidgetTitle {
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartWidgetScore {
  font-size: var(--default-text);
  color: var(--white);
}

.chartWidgetGraphContainer {
  width: 100%;
  height: calc(100% - 28px);
}

/// collapsed

.chartWidgetContainerCollapsed {
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  border-radius: 4px;
  height: fit-content;
  width: 100%;
}

.chartWidgetLeftPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  gap: 8px;
}

.chartWidgetScoreCollapsed {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
}

.chartWidgetNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chartWidgetNoDataText {
  font-size: var(--default-text);
  color: var(--grey-accent);
}

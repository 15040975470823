.table {
  width: 100%;
  height: fit-content;
  overflow-x: auto;
  overflow-y: auto;
  color: #f0f0f0;
}

.BlockTable {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 8px;
}

.headerRow {
  height: 36px;
}

table .tableData {
  white-space: nowrap;
  font-size: var(--default-text);
  padding: 0 20px;
}

.totalRow {
  font-size: 15px !important;
}

.tableData.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--background-primary);
  height: 36px;
  width: 340px !important;
}

.stickyColumn15 {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--background-primary);
  height: 36px;
  width: 100px;
}

.stickyColumnHeader15 {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--background-secondary);
  height: 36px;
  width: 100px;
}

.tableData.stickyColumnHeader {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--background-secondary);
  height: 36px;
  color: var(--white);
  width: 340px !important;
}

.highlightedSticky {
  background-color: var(--background-secondary) !important;
}

.tableRow {
  border-bottom: 2px solid var(--background-secondary);
  height: 36px;
  color: var(--off-white);

  &:hover {
    background-color: var(--background-secondary);
  }
}

.stickyColumn2 {
  white-space: nowrap;
  font-size: var(--default-text);
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--background-primary);
  width: 76px !important;
  max-width: 76px !important;
}

.stickyColumn2Header {
  white-space: nowrap;
  font-size: var(--default-text);
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--background-secondary);
  color: var(--white);
  width: 76px !important;
  max-width: 76px !important;
}

.stickyColumn4 {
  white-space: nowrap;
  font-size: var(--default-text);
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--background-primary);
  width: 2px !important;
  max-width: 2px !important;
}

.stickyColumnHeader4 {
  white-space: nowrap;
  font-size: var(--default-text);
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--background-secondary);
  color: var(--white);
  width: 2px !important;
  max-width: 2px !important;
}

.lemon {
  height: 120%;
  width: 2px !important;
  background-color: var(--grey-accent);
  padding: 0;

  max-width: 2px !important;
}

.tableContainer {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: #f0f0f0;
  font-size: var(--default-text);
  background-color: var(--background-secondary);
}

.cagrColumn {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: var(--background-primary);
}

.stickyColumn3 {
  position: sticky;
  right: 75px;
  z-index: 1;
  background-color: var(--background-primary);
}

.metricRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  gap: 8px;
  padding-top: 8px;
}

.noRows {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: var(--large-text);
  color: var(--grey-accent);
}

.chartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-primary);
  user-select: none;
  height: 200px;
  width: 100%;
  height: calc(100vh - 200px);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  gap: 8px;
}

.cagrColumnHeader {
  padding: 0 8px;
}

.standardViewContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: calc(100vh - 62px);
}

.companyNewsViewComponent {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  height: var(--content-view-height);
}

.companyNewsViewComponentLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: var(--content-view-height);
}

.chartLabel {
  font-size: 14px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 24px;
}

.companyNewsViewComponentLeftTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 318px);
}

.companyNewsViewComponentLeftBottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.companyNewsViewComponentRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
  height: var(--content-view-height);
  border-radius: 4px;
  background-color: var(--background-primary);
}

.companyNewsViewComponentRightTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  background-color: var(--background-primary);
  border-radius: 4px;
}

.companyNewsViewComponentRightBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: fit-content;
  width: 100%;
}

.companyNewsViewExpendedChartsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.companyNewsViewComponentRightBottomChild {
  background-color: var(--background-primary);
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebarIcon {
  padding: 0px 8px;
  cursor: pointer;
}

.companyNewsViewComponentLeftBottomBottomChildLabel {
  font-weight: var(--bold-text);
  font-size: 14px;
  color: var(--white);
}

.companyNewsViewComponentLeftBottomBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px 12px 0 0;
}

.companyNewsViewComponentLeftBottomTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  height: 50px;
  width: 100%;
}

.loadingData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.collapsedSideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px 0;
  gap: 8px;
}

.totalsTrendWidgetWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
}

.divider {
  margin-top: 8px;
  width: 70%;
  height: 1px;
  background-color: var(--grey-accent);
}

.bottomBarIcon {
  padding: 0px 8px;
  cursor: pointer;
  rotate: 270deg;
}

.bottomBarIconUp {
  padding: 0px 8px;
  cursor: pointer;
  rotate: 90deg;
}

.bottomBar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.bottomBarWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
}

.bottomBarValue {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
}

.bottomBarLabel {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
  white-space: nowrap;
}

.bottomBarPairWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.bottomBarDivider {
  height: 75%;
  width: 1px;
  background-color: var(--grey-accent);
}

.stocktwitsLogo {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

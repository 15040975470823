.categoryPillWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 6px;
  padding-left: 6px;
  border-radius: 4px;
  gap: 6px;
  background-color: var(--background-tertiary);
  cursor: pointer;
  border: 2px solid transparent;
  height: 28px;
  transition: all 0.1s ease-in-out;
  border: 1px solid var(--background-tertiary);
  box-sizing: border-box;
}

.categoryPillText {
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
  user-select: none;
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 20%;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.pillsTitle {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.segmentTitle {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}

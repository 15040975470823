.customized-tooltip-content-core {
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  width: fit-content;
  z-index: 10000;
  padding: 4px 4px 4px 4px;
  margin: 0;
  height: fit-content;
  border-radius: var(--border-radius);
}

.marketOverviewContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 33.3% 33.3% 33.3%;
  height: var(--view-height);
  flex-direction: column;
  background-color: var(--outseek-background);
  width: 100%;
}

.marketOverviewTopPanel {
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 8px;
}

.marketOverviewBottomPanel {
  height: 100%;
  gap: 8px;
  display: flex;
  padding-top: 4px;
}

.vertical-time-range-container {
  width: 50px;
  height: 100%;
}
.marketOverviewMiddlePanel {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 8px;
  border-radius: 4px;
  padding-bottom: 4px;
  // background-color: var(--background-primary);
}

.market-overview-table-trend-wrapper {
  height: 100%;
  width: 50%;
  display: flex;
}

.table-trend-chart-time-range-wrapper {
  height: 60px;
}

.selectable-table-wrapper {
  height: 100%;
  min-width: 260px;
  width: 100%;
}
.selectable-table-wrapper1 {
  height: 90%;
  min-width: 130px;
  width: 100%;
}
.MarketOverviewTabs {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 24px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  font-size: var(--default-text);
  user-select: none;
  white-space: nowrap;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.marketMoverTabsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--grey-accent);
}

.tabListHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0;
  margin: 0;
  border-bottom: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  overflow: auto;
}

.selectedTab {
  background-color: var(--grey-accent);
}

.tabDivider {
  height: 14px;
  border: 0.5px solid var(--grey-accent);
  // background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.invisibleTabDivider {
  height: 14px;
  border: 0.5px solid transparent;
  // background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

// .forexWrapper {
//   width: 100%;
// }

.marketMovers {
  width: fit-content;
  // width: 33%;
}

.mapsButton {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  color: var(--white);
  font-size: var(--default-text);
  cursor: pointer;
}

.mapsLink {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--white);
}

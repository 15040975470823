.screenerViewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--view-height);
  width: 100%;
  padding: 6px;
  background: var(--background-primary);
  border-radius: 4px;
}

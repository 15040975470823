.outseekInput {
  background: var(--background-secondary);
  border-radius: 4px;
  color: var(--white);
  padding: 0 8px;
  width: 100%;
  height: 100%;
  border: 2px solid var(--button-hover);

  &:focus {
    transition: 0.3s ease;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

//////// Homepage Pre Footer /////////

.homepage-pre-footer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 40px 250px;
  background-color: var(--background-secondary);
}

.homepage-pre-footer-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.homepage-pre-footer-content-title {
  color: var(--white);
  font-size: 64px;
  font-weight: var(--bold-text);
  width: 100%;
  text-align: center;
  line-height: 1;
}

.homepage-pre-footer-content-title-text {
  color: var(--white);
  font-size: 20px;
  font-weight: var(--bold-text);
  width: 100%;
  text-align: center;
}

//////// Homepage Footer /////////

.homepage-footer {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: space-between;
  padding: 40px 15%;
}

.homepage-footer-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 24px;
  height: fit-content;
  width: fit-content;
}

.homepage-footer-content-title {
  color: var(--light-grey-accent);
  font-size: 22px;
  font-weight: var(--bold-text);
}

.homepage-footer-content-link {
  color: var(--white);
  font-size: 16px;
  font-weight: var(--bold-text);
  white-space: nowrap;
  line-height: 18px;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--primary-color);
  }
}

.homepage-footer-content-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

///////// Homepage copyright /////////

.homepage-copyright {
  padding: 20px 25%;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-copyright-content {
  color: var(--light-grey-accent);
  font-size: var(--large-text);
  font-weight: var(--bold-text);
}

///////// Media Queries /////////

$breakpoint-tablet: 1080px;
$breakpoint-mobile: 460px;

@media (max-width: $breakpoint-tablet) {
  .homepage-footer {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    white-space: wrap;
    justify-content: space-between;
    padding: 30px 20px;
    gap: 26px;
  }
  .homepage-footer-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 24px;
    height: fit-content;
    width: fit-content;
  }
  .homepage-footer-content-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .homepage-copyright {
    padding: 20px 20px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homepage-copyright-content {
    color: var(--light-grey-accent);
    font-size: var(--large-text);
    font-weight: var(--bold-text);
  }
  .homepage-pre-footer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 44px;
    background-color: var(--background-secondary);
  }

  .homepage-pre-footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .homepage-pre-footer-content-title {
    color: var(--white);
    font-size: 32px;
    font-weight: var(--bold-text);
    width: 100%;
    text-align: center;
    line-height: 1;
  }

  .homepage-pre-footer-content-title-text {
    color: var(--white);
    font-size: 20px;
    font-weight: var(--bold-text);
    width: 100%;
    text-align: center;
  }
}

@media (max-width: $breakpoint-mobile) {
  .homepage-footer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 26px;
  }
  .homepage-footer-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 24px;
    height: fit-content;
    width: fit-content;
  }
  .homepage-footer-content-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .homepage-copyright {
    padding: 20px 20px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homepage-copyright-content {
    color: var(--light-grey-accent);
    font-size: var(--large-text);
    font-weight: var(--bold-text);
  }
  .homepage-pre-footer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: var(--background-secondary);
  }

  .homepage-pre-footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .homepage-pre-footer-content-title {
    color: var(--white);
    font-size: 32px;
    font-weight: var(--bold-text);
    width: 100%;
    text-align: center;
    line-height: 1;
  }

  .homepage-pre-footer-content-title-text {
    color: var(--white);
    font-size: 20px;
    font-weight: var(--bold-text);
    width: 100%;
    text-align: center;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 56px;
  gap: 4px;
  font-weight: var(--bold-text);
}

.createScreenWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: var(--large-text);
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.screen {
  display: flex;
  gap: 8px;
  font-size: var(--large-text);
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-secondary);
  border-radius: 4px;
  padding: 0 8px;
}

.utilsButtons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.desc {
  display: flex;
  gap: 8px;
  color: var(--light-grey-accent);
}

.title {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  user-select: none;
}

.nameDetails {
  display: flex;
  gap: 8px;
  align-items: center;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  gap: 12px;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-hover);
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 232px;
  gap: 4px;
  font-weight: var(--bold-text);
}

.displayFilters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--light-grey-accent);
  font-size: var(--large-text);
  height: 100%;
  overflow-y: auto;
}

.screener {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailAccordion {
  width: 100%;
  height: fit-content;
  max-height: 130px;
  overflow-y: auto;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-secondary);
  padding: 8px;
  font-size: var(--large-text);
}

.companyQuoteRow {
  border-bottom: 2px solid var(--background-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 400px;
  height: 100%;
  background: var(--background-primary);
}

.companyQuoteCell1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 135px;
  height: 24px;
  order: 0;
}

.companyQuoteCell2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 65px;
  height: 24px;
  order: 1;
}

.companyQuoteCell3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 135px;
  height: 24px;
  order: 2;
}

.companyQuoteCell4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 65px;
  height: 24px;
  order: 3;
}

.companyQuoteContentRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 4px;
  width: 65px;
  height: 24px;
  order: 0;
  color: var(--white);
  font-size: var(--default-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyQuoteContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  width: 135px;
  height: 24px;
  color: var(--off-white);
  font-size: var(--default-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MarketMoverTabs {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 24px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  font-size: var(--default-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.selected-tab {
  background-color: var(--grey-accent);
}

.MarketMoverTabsList {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 6px;
  height: 100%;
  padding: 4px 8px 0 8px;
  gap: 8px;
}

.react-tabs__tab-panel {
  padding: 0 !important;
  margin: 0 !important;
}

.react-tabs__tab-panel--selected {
  padding: 0 !important;
  margin: 0 !important;
}

.TabListHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0;
  margin: 0;
  border-bottom: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  overflow: auto;
}

.TabList {
  display: flex;
  flex-direction: column;
  // width: fit-content;
  padding: 0;
  margin: 0;
  // border-bottom: none;
  // border-top: none;
  // border-bottom: none;
  border-right: 2px solid var(--background-secondary);
}

.CatalogTabs {
  height: 100%;
  width: 100%;
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 8px;
  // font-weight: var(--bold-text);
  background-color: var(--background-primary);
  // border-radius: 4px;
  cursor: pointer;
  font-size: var(--default-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.TabSectionWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-bottom: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.TabDivider {
  height: 14px;
  border: 0.5px solid var(--grey-accent);
  // background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.invisibleTabDivider {
  height: 14px;
  border: 0.5px solid transparent;
  // background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}
.MarketMoverTabsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--grey-accent);
}

.dataRowBitsizeLabel {
  display: flex;
  justify-content: space-between;
  color: var(--white);
  align-items: center;
}

.dataRowBitsizeData {
  display: flex;
  justify-content: space-between;
  color: var(--white);
  align-items: center;
  gap: 4px;
}

.widgetLabel {
  font-size: 16px;
}

.widgetTicker {
  font-size: 16px;
}

.widgetDataLabel {
  margin-top: 12px;
  font-size: 14px;
  width: 55px;
  display: flex;
  justify-content: flex-end;
}

.widgetMetricLabel {
  max-width: 190px;
  width: 100%;
  white-space: nowrap;
  margin-top: 12px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataRowLabelDataWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bitesizeComponent {
  height: 200px;
  width: 100%;
}

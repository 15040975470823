.chartContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--light-grey-accent);
  font-size: var(--default-text);
}

.earningsWrapper {
  width: 100%;
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.earningsContentWrapper {
  width: 100%;
  height: var(--content-view-height);
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  color: var(--white);
}

.swapButton {
  width: fit-content;
  height: 80%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: var(--default-text);
  color: var(--white);
  font-weight: var(--bold-text);
  padding: 0 8px;
  border-radius: 4px;
  margin-right: -4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.tableWrapper {
  width: fit-content;
}

.bottomWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
}

.desktopFeatureWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 12px;
  padding: 20px;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
  max-height: 680px;
}

.desktopFeatureLabelWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.desktopLabelTitle {
  font-size: 20px;
  line-height: 1;
  text-align: left;
  font-weight: var(--bold-text);
}

.desktopLabelSubTitle {
  font-size: 16px;
  text-align: left;
  color: var(--light-grey-accent);
}

.imageWrapper {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.desktopFeatureComponent {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

/// Media Queries

@media screen and (min-width: 1080px) and (max-width: 1399px) {
  .desktopFeatureWrapper {
    gap: 20px;
  }
  .desktopLabelTitle {
    font-size: 20px;
  }
  .desktopLabelSubTitle {
    font-size: 14px;
  }
}

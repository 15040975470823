.authModalWrapper {
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--outseek-background);
  border-radius: 8px;
  padding: 16px;
  border: 4px solid var(--background-tertiary);
}

.authWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.authModalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.closeButton {
  border-radius: 4px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  &:hover {
    background-color: var(--button-hover);
  }
}

.authModalHeaderText {
  color: var(--white);
}

.modalFocused {
  &:focus {
    outline: none !important;
  }
}
.modalFocused :focus {
  outline: none !important;
}

.navbarWrapper {
  width: 100%;
}

.homepageNavbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 150px;
  position: fixed;
  z-index: 100000;
  background-color: var(--outseek-background);
}

.progress-bar {
  position: fixed;
  height: 71px;
  background: var(--primary-color);
  transform-origin: 0%;
  width: 100%;
  z-index: 10000;
}

.navbarIcons,
.navbarLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.navBarItem {
  font-size: 17px;
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
  cursor: pointer;
  transition: color 0.1s ease-out;

  &:hover {
    color: var(--primary-color);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropBtn {
  background: none;
  border: none;
  padding: 0;
  font-size: 17px;
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
  cursor: pointer;
  outline: none;

  &:hover {
    color: var(--primary-color);
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: var(--outseek-background);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--grey-accent);
  border-radius: 8px;
  z-index: 1;

  a {
    color: var(--light-grey-accent);
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: var(--primary-color-light);
    }
  }
}

.dropdown:hover .dropdownContent {
  display: block;
}

.socialIcon {
  &:hover {
    transition: transform 0.3s ease-out;
    transform: scale(1.1);
  }
}

// Responsive Styling
$breakpoint-tablet: 1080px;
$breakpoint-mobile: 460px;

$navPadding-tablet: 14px 75px;
$navPadding-mobile: 14px 35px;

@media (max-width: $breakpoint-tablet) {
  .homepageNavbar {
    padding: $navPadding-tablet;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    position: fixed;
    gap: 10px;
    top: 68px;
    background-color: var(--outseek-background);
    width: 100%;
    height: fit-content;
    padding: $navPadding-tablet;
    z-index: 10000000000000000;
    border-bottom: 1px solid var(--grey-accent);
  }
}

@media (max-width: $breakpoint-mobile) {
  .homepageNavbar {
    padding: $navPadding-mobile;
  }

  .mobileMenu {
    padding: $navPadding-mobile;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.MenuItem {
  color: transparent;
  width: calc(100% - 10px);
  white-space: nowrap;
  justify-content: space-between;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--button-padding);
  cursor: pointer;
  border-radius: var(--border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 2px;
  margin-left: 10px;
  user-select: none;
  gap: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: ease 0.1s;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ExpandableMenuItem {
  // color: transparent;
  width: 100%;
  white-space: nowrap;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--button-padding);
  cursor: pointer;
  border-radius: var(--border-radius);
  margin-bottom: 2px;
  margin-left: 10px;
  user-select: none;
  gap: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: ease 0.1s;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.themeModal {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 650px;
  height: 485px;
  background-color: var(--background-primary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  color: var(--white);
  font-weight: var(--bold-text);
}

.themeOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 0px 12px;

  overflow-y: auto;
}

.colorOptionWrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.colorPallet {
  height: 80px;
  display: flex;
}

.themeOption {
  color: var(--white);
  font-size: var(--large-text);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.themeNameWrapper {
  color: var(--white);
  font-size: var(--large-text);
  display: flex;
  gap: 8px;
  width: 100%;
}

.themeName {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeIcon {
  cursor: pointer;
  color: var(--white);
  font-size: var(--large-text);
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.selectedBox {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1.5px solid var(--light-grey-accent);
}

.colorInputContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  height: 100%;
}

.colorLabel {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: var(--large-text);
  font-weight: 600;
  gap: 4px;
  height: 100%;
}

.editorWrapper {
  display: flex;
  gap: 8px;
  width: 100%;
}

.colorsSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100%;
}

.colorInput {
  width: 100%;
  border-radius: 6px;
  height: 100%;
}

.nameInput {
  width: 100%;
  height: 32px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.confirmTheme {
  display: flex;
  align-items: center;
  gap: 8px;
}

.saveThemeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 12px;
  color: var(--white);
  background-color: var(--primary-color);
  border-radius: 4px;
  font-size: var(--large-text);
  height: 32px;
  font-weight: var(--bold-text);
  cursor: pointer;
  width: 100%;
}

.createThemeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 12px;
  color: var(--white);
  background-color: var(--grey-accent);
  border-radius: 4px;
  font-size: var(--large-text);
  height: 32px;
  font-weight: var(--bold-text);
  cursor: pointer;
}

.themeCreatorWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.themeEditorWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

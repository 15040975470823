.categoryTag {
  padding: 0px 6px;
  border-radius: 4px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  font-weight: var(--bold-text);
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 2px;
}

.description {
  width: 280px;
  text-wrap: wrap;
  color: #d7d7d7;
}

.metricInfo {
  background-color: rgba(33, 33, 33, 1);
  border: 1.5px solid rgb(125, 125, 125);
  width: fit-content;
  z-index: 1000000000000000000;
  padding: 6px;
  margin: 0;
  height: fit-content;
  border-radius: var(--border-radius);
  font-size: var(--default-text);
  color: var(--white);
  font-weight: normal !important;
}

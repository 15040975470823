.itemIconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: fit-content;
  width: 100%;
}

.containerTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 6px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 140px;
  background: var(--background-tertiary);
  border-radius: var(--border-radius);
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 12px;
  padding-bottom: 12px;
}

.item:hover {
  background-color: var(--background-secondary);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.itemFavourite {
  float: right;
}

.itemTitle {
  font-style: normal;
  font-size: 15px;
  margin: 0;
  color: var(--white);
  white-space: wrap;
  font-weight: 600;
}

.plusIcon {
  width: 14px;
  height: 14px;
}

.arrowIcon {
  width: 14px;
  height: 14px;
}

.buttonTitleWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
}

.addButton {
  content: '\002B';
  cursor: pointer;
  color: var(--white);
  display: flex;
  padding: 0 8px;
  border-radius: 8px;
  font-size: var(--default-text);
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
}

.accordion {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  cursor: pointer;
  padding: 12px;
  width: 24px;
  height: 24px;
}

.panel {
  width: 100%;
  color: var(--white);
  font-size: var(--default-text);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.itemDesc {
  font-size: var(--default-text);
  color: var(--white);
  overflow: auto;
  text-overflow: ellipsis;
}

//// modal styles ////

.itemModalBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
}

.itemModal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  background-color: var(--background-primary);
  padding: 12px;
  gap: 8px;
  color: var(--white);
  border-radius: 4px;
  max-width: 60vw;
}

.itemModalDesc {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.itemModalImage {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(69, 91, 108);
  border-radius: 4px;
  height: 100%;
  user-select: none;
}

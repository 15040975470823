.checkboxdiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noCheckboxdiv {
  width: 0;
  height: 100%;
}

.topHeaderToolBar {
  position: sticky;
  height: 36px;
  width: 100%;
}
.TimeSelect {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.headerTableRow {
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.PlotDataWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
v .functionButton {
  cursor: pointer;
  border-radius: var(--border-radius);
  height: 28px;
  display: flex;
  padding: var(--button-padding);
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.text {
  color: var(--white);
  font-size: var(--large-text);
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.functionButtonPlot {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  height: 28px;
  padding: var(--button-padding);

  &:hover {
    background-color: var(--button-hover);
    transition: var(--button-transition);
  }
}

.textPlot {
  color: var(--white);
  font-size: var(--large-text);
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.space {
  width: 12px;
}

.tr2 {
  margin-left: 12px;
}

.icons {
  width: 16px;
  height: 16px;
  margin: 4px;
  cursor: pointer;
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--button-hover);
  }
}
.emptyIcon {
  width: 16px;
  height: 16px;
}

.table-div::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.table-div::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}
.table-div::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

.table-row {
  border-bottom: 2px solid var(--background-secondary);
  box-sizing: border-box;

  &:hover {
    transition: var(--button-transition);
    background-color: var(--background-secondary);
    // border: none;
  }
}

.highlight {
  background-color: var(--background-secondary);
}

.blockTableHeaders {
  padding-left: 1.5rem;
  padding-right: 12px;
  font-weight: 700;
  font-size: 12px;
  background-color: var(--background-primary);
  z-index: 1000;
  color: var(--white);
  left: 40;
}

.blockTableHead {
  height: 4px;
  width: 100%;
  background-color: var(--background-primary);
}

.blockTableData {
  padding-left: 1.5rem;
  padding-right: 12px;
  font-weight: var(--bold-text);
  font-size: 12px;
  max-width: 300px;
  overflow-x: hidden;
  // background-color: ;
  height: 32px;
  white-space: nowrap;
  color: var(--white);
}

.expandableTableData {
  width: 12px;
}

.blockTable {
  background-color: var(--background-primary);
  overflow-y: scroll;
}

.BlockTable {
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.secondColumnData {
  padding-left: 1.5rem;
  padding-right: 12px;
  font-weight: var(--bold-text);
  font-size: 12px;
  width: 300px;
  overflow-x: hidden;
  // background-color: ;
  height: 32px;
  white-space: nowrap;
  color: var(--white);
}

.sticky-row {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--background-secondary);
}

.ItemText {
  font-style: normal;
  font-weight: 400;
  font-size: var(--large-text);
  line-height: 14px;
  color: var(--white);
}
.navBarContainer {
  width: 420px;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
.navBar {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.navItem {
  cursor: pointer;
  border-radius: var(--border-radius);
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: var(--large-text);
  line-height: 14px;
  color: var(--white);

  &:hover {
    background-color: var(--button-hover);
    transition: 0.2s ease-out;
  }
}

.navItemActive {
  height: 100%;
  width: 100%;
  padding: var(--button-padding);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomBarContainer {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
}

.leftBottom {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
}

.rightBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}

.leftItem {
  flex-grow: 1;

  font-style: normal;
  font-weight: 400;
  font-size: var(--large-text);
  line-height: 14px;
  color: var(--white);
}

.rightItem {
  flex-grow: 1;
  margin-bottom: 8px;
}

.functionButton {
  cursor: pointer;
  border-radius: var(--border-radius);
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.2s ease-out;
  }
}

.text {
  color: var(--white);
  font-size: var(--large-text);
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.compChartView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  gap: 8px;
}

.compChartWrapper {
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  background-color: var(--background-primary);
}

.aboutUs {
  height: 100%;
  // min-height: 100vh;
  width: 100%;
  color: var(--white);
  background-color: var(--outseek-background);
  padding: 100px 50px;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.titleText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--light-grey-accent);
  font-size: 18px;
  max-width: 600px;
}

.pillar {
  height: 60px;
  width: 8px;
  background-color: var(--primary-color);
  border-radius: 10px;
}

.aboutUsTitle {
  font-size: 52px;
  font-weight: var(--bold-text);
  white-space: nowrap;
}

.contactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: var(--light-grey-accent);
}

.teamGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.teamMember {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 12px;
  padding: 20px;
}

.item {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.memberImage {
  border-radius: 10px;
  width: 250px;
  margin-bottom: 1rem;
  user-select: none;
}

.nameText {
  font-size: 32px;
  font-weight: var(--bold-text);
  color: var(--white);
}

.positionText {
  font-size: 18px;
  color: var(--light-grey-accent);
}

.note {
  font-size: 14px;
  color: var(--light-grey-accent);
  max-width: 250px;
}

@media (max-width: 700px) {
  .title {
    flex-direction: column;
    gap: 20px;
  }
  .teamGrid {
    grid-template-columns: 1fr;
  }
  .pillar {
    display: none;
  }
}

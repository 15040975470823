.featureTabsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  gap: 100px;
  height: 100%;
  width: 100%;
  position: relative;
}

.tabsWrapperWithTitle {
  position: sticky;
  top: 200px;
  align-self: flex-start;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 40px;
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
}

.tabsWrapper {
  position: sticky;
  top: 200px;
  align-self: flex-start;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 100px;
}

.tabContent {
  margin-bottom: 200px;
  height: fit-content;
}

.image {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.tab {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: var(--white);
  white-space: nowrap;
  font-size: 15px;
  cursor: pointer;
  font-weight: var(--bold-text);
}

.title {
  font-size: 40px;
  font-weight: 600;
  color: var(--white);
  margin-bottom: 8px;
}

.subtitle {
  font-size: 18px;
  font-weight: 500;
  color: var(--light-grey-accent);
  margin-bottom: 40px;
}

.marker {
  width: 6px;
  height: 6px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .featureTabsWrapper {
    flex-direction: column;
    gap: 0;
    padding: 10px 30px;
  }

  .tabsWrapperWithTitle {
    padding: 0;
    width: 100%;
  }

  .tabsTitle {
    text-align: center;
    font-size: 32px;
  }

  .tabsWrapper {
    position: sticky;
    flex-direction: row;
    top: 0;
    width: 100%;
    overflow: auto;
    padding: 8px 0;
    gap: 16px;
  }

  .tabsWrapper::-webkit-scrollbar {
    display: none;
  }

  .contentWrapper {
    padding-right: 0;
  }

  .marker {
    display: none;
  }

  .tabContent {
    margin-bottom: 40px;
  }

  .tab {
    font-size: 18px;
  }

  .title {
    font-size: 32px;
  }

  .subtitle {
    font-size: 16px;
  }
}

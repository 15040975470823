.cognitoWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.accountPageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 380px;
}

.account {
  color: var(--white);
}
.accountBlocks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 800px;
  height: 100%;
}

.accountBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  background: var(--background-primary);
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.accountDetails {
  width: 100%;
  height: fit-content;
  font-size: 18px;
  color: var(--white);
}
.accountActionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.inputSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
}

.confirmButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: fit-content;
}

.buttons {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 32px;
  background: var(--primary-color);
  border-radius: 8px;
}

.navListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 17px;
  font-size: var(--large-text);
  color: var(--white);
}

.authInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--background-secondary);
  border-radius: 4px;
  width: 100%;
  height: 36px;
  color: var(--white);
  // padding: 0 8px;
}

.accountTitle {
  width: 100%;
  height: fit-content;
  color: var(--white);
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.authInfoStale {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputLabel {
  font-size: var(--large-text);
  color: var(--white);
}

.modelFrameConfirmTagsContainer {
  padding: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 50px;
  background: var(--background-primary);
  border-radius: 8px;
  border: 2px solid var(--button-hover);
}

.modelFrameConfirmTags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  width: 275px;
  height: 17px;
}

.titleConfirmTags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  width: 275px;
  height: 17px;
}

.titleConfirmTagsMessage {
  font-size: 14px;
  color: var(--green);
  font-weight: 500;
}

.titleConfirmTagsClose {
  color: var(--white);
  cursor: pointer;
}

.passwordNoMatch {
  color: var(--red);
  padding: 2px 8px;
  height: 100%;
  font-size: var(--large-text);
}

.TitleConfirmTagsClose {
  &:hover {
    background: var(--button-hover);
    border-radius: 4px;
  }
}

/* Tablet devices */
@media (max-width: 1024px) {
  .accountPageContainer {
    height: auto;
    padding: 32px 0;
  }

  .accountBlocks {
    flex-direction: column;
    align-items: center;
    width: 400px;
  }

  .accountBlock {
    width: 100%;
    height: auto;
  }
}

/* Mobile devices */
@media (max-width: 460px) {
  .accountPage {
    padding: 40px;
  }
  .accountPageContainer {
    width: 100%;
  }
  .accountTitle {
    font-size: 20px;
  }

  .accountDetails {
    font-size: 16px;
  }

  .accountBlocks {
    width: 100%;
  }

  .authInputWrapper {
    height: 40px;
  }

  .confirmButtonWrapper {
    height: auto;
  }

  .buttons {
    height: 40px;
  }
}

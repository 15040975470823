.etfWrapper {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.etfViewWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  height: var(--content-view-height);
  color: var(--white);
  gap: 8px;
}

.visualizations {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 212px);
  gap: 8px;
}

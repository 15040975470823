#doorbell-button {
  display: none !important;
}

#doorbell-form {
  background: var(--background-primary) !important;
  border: 2px solid var(--background-tertiary) !important;
  box-shadow: none !important;
}

#doorbell-title {
  color: var(--white) !important;
  border-bottom: none !important;
  margin: 0 0 6px 0 !important;
  font-size: 16px !important;
}

#doorbell-background {
  background: rgb(18, 18, 18) !important;
}

#doorbell-close {
  color: var(--white) !important;
  font-size: 28px !important;
  padding: 0 !important;
  top: 8px !important;
  font-weight: 200 !important;
}

#doorbell-success {
  margin-bottom: 8px !important;
  padding: 8px !important;
}

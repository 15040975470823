.gridItemTitle {
  font-size: 14px;
  color: var(--white);
  text-align: left;
}

.gridItemGraph {
  border-left: 2px solid var(--button-hover);
  border-right: 2px solid var(--button-hover);
  border-bottom: 2px solid var(--button-hover);
  border-radius: 0 0 4px 4px;
  padding: 6px 8px;
  background-color: var(--background-primary);
}

.changeDataFrequencyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
  color: var(--white);
  cursor: pointer;
  white-space: nowrap;
}

.dataFrequencyButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  user-select: none;
  white-space: nowrap;
}

.divider {
  width: 1px;
  height: 60%;
  background-color: var(--grey-accent);
}

.dataFrequencyButtonCARD {
  // background-color: var(--background-primary);
  padding: 4px 8px;
  font-size: var(--default-text);
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  user-select: none;
  white-space: nowrap;
}

.changeDataFrequencyWrapperCARD {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0;
  border-top-right-radius: 4;
  // border: 1px solid var(--grey-accent);
  height: 24px;
  color: var(--white);
  cursor: pointer;
  white-space: nowrap;
}

.dividerCARD {
  width: 1px;
  height: 60%;
  background-color: var(--light-grey-accent);
}

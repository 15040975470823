/// MODALS

.watchlistInfoModal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.watchlistInputTitle {
  border-radius: 6px;
  border: 2px solid var(--light-grey-accent);
  background-color: transparent;
  padding: 6px 8px;
  font-size: var(--large-text);

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.watchlistInputDesc {
  border-radius: 6px;
  border: 2px solid var(--light-grey-accent);
  background-color: transparent;
  padding: 6px 8px;
  font-size: var(--large-text);
  height: 120px;
  max-height: 120px;
  min-height: 120px;

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.addTickerModal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

//// Error Note
.watchlistError {
  font-size: 12px;
  color: var(--red);
  margin-top: -4px;
}

.noSymbolContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.insideWrapper {
  height: 100%;
}

.noWatchlistsAvailable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeModal {
  height: 24px;
  width: 24px;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.watchlistInfoModalHeader {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.modalConfirmButton {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}

.deleteWatchlistQuestion {
  font-size: var(--default-text);
  color: var(--white);
}

.lotsOfChartsWrapper {
  height: calc(100vh - 162px);
  overflow: auto;
  width: 100%;
  margin-top: 8px;
}

.lotsOfCharts {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lotsOfChartsChart {
  width: calc(100% - 20px);
  height: 120px;
  margin: 8px;
}

.lotsOfChartsHeader {
  font-size: var(--default-text);
  width: calc(100% - 20px);
  background-color: var(--background-secondary);
  border-radius: 4px;
  padding: 0 12px;
  white-space: nowrap;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lotsOfChartsHeaderTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lotsOfChartsHeaderValue {
}

.lotsOfChartsToolBar {
  height: 48px;
  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: var(--background-primary);
  z-index: 10;
  gap: 8px;
}

.lotsOfChartsToolBarButtons {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
}

.rangeContainer {
  width: 100%;
  height: 100%;
  padding: 12px 30px;
  max-width: 1400px;
}

.sliderTrack {
  margin-top: 8px;
  height: 2px;
  background-color: var(--light-grey-accent);
  border-radius: 2px;
}

.sliderThumb {
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.sliderThumbDragging {
  width: 16px;
  height: 1px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: relative;
}

.sliderThumbDragValue {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--default-text);
  color: var(--white);
}

.sliderMark {
  position: absolute;
  height: 12px;
  width: 4px;
  background-color: var(--background-primary);
}

.markValue {
  position: absolute;
  top: 12px;
  transform: translateX(-6px);
  font-size: var(--default-text);
  color: var(--white);
}

.ScreenerRow {
  border-bottom: 2px solid var(--background-secondary);
}

.paginationWrapper1 {
  width: 100%;
  height: 50px;
  color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 8px;
  height: fit-content;
}

.dataWarning {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  width: 100%;
  text-align: end;
}
.paginationWrapper {
  background-color: var(--background-primary);
  width: fit-content;
  display: flex;
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  line-height: 13px;
  text-align: center;
  height: 50px;
  color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: fit-content;
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
}
.pagination__link {
  color: #0092e4;
  background-color: var(--background-primary);
  border: 0px;
}

.pageSelect {
  height: 24px;
  background-color: var(--background-primary);
  color: var(--white);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.pageOption {
  background-color: var(--background-primary);
  color: var(--white);
}
.pagination-info {
  padding: 12px;
}

.selectWrapper {
  height: 24px;
  cursor: pointer;
}

.table-wrapper {
  width: 100%;
  overflow: scroll;
  background-color: var(--background-primary);
}

.table-wrapper-no-data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-grey-accent);
  font-size: var(--large-text);
  background-color: var(--background-primary);
}

.filter-pill-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  padding-right: 4px;
  gap: 4px;
  width: fit-content;
  height: 28px;
  background: var(--background-tertiary);
  border-radius: 4px;
  white-space: nowrap;
}

.filter-label {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  // line-height: 13px;
  color: var(--white);
}

.filter-pill-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  overflow: auto;
}

.metric-catalog-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 34px;
  background: var(--background-primary);
  font-weight: var(--bold-text);
  font-size: var(--large-text);
  cursor: pointer;
  color: var(--primary-color);
}

.screenerfinalwrapper {
  display: flex;
  gap: 6px;
  width: 100%;
  height: 100%;
  background: var(--background-primary);
}

.screener-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-primary);
}

.metric-catalog-container {
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: var(--background-primary);
  border-radius: var(--border-radius);
}

.tab-list {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
  margin: 0;
}

.tab {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  background: #f0f0f0;
  border-right: 1px solid #ccc;
}

.tab:last-child {
  border-right: none;
}

.tab:hover {
  background: #ddd;
}

// .selected-tab {
//   background: #ccc;
// }

.metric-catalog-title-container {
  width: 400px;
  height: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);
  display: flex;
  justify-content: space-between;
}

.metric-catalog-preset-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 1040px;
  height: 36px;
}

.metric-catalog-filters-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 1038px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.metric-catalog-preset-container-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 1070px;
  height: 36px;
}

.metric-catalog-preset-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  height: 36px;
}

.metric-catalog-preset-text {
  height: 14px;

  font-style: normal;
  font-weight: 700;
  font-size: 11.2049px;
  line-height: 14px;
  text-decoration-line: underline;
  color: var(--white);
}

.metric-catalog-filter-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 400px;
}

.filter-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white);
  // height: 28px;
  height: fit-content;

  gap: 8px;
  padding: 4px 8px;
  width: 100%;
}

.filter-input-label {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 12px;
  font-size: 12.3px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space: wrap;
  color: var(--off-white);
  text-align: left;
  width: 100%;
}

.filter-input-min-max {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: fit-content;
  font-size: 13px;
  gap: 4px;
}

.filter-input-string {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: fit-content;
  height: 14px;
}

.metric-catalog-close-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 0px;
  color: var(--white);
  width: 100%;
  height: 30px;
  cursor: pointer;
  background-color: var(--primary-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  user-select: none;
}

.metric-catalog-close-container-text {
  font-size: var(--large-text);
  color: var(--white);
}

.delete-icon {
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.FactorSelect {
  background-color: var(--background-tertiary) !important;
  border-radius: 4px;
}

.filter-input-unit-option {
  cursor: pointer;
}

.filter-input-unit {
  border: 1px solid var(--grey-accent);
  border-radius: 4px;
  background-color: var(--background-tertiary) !important;
  cursor: pointer;
}

.filter-input-unit {
  width: 40px;
}

.filter-input-min {
  height: 24px;
  width: 60px;
  font-size: 12px;
  background-color: var(--background-tertiary) !important;
  border: 1px solid var(--grey-accent);
  border-radius: 4px;
  padding: 0px 6px;
  margin-right: 4px;
  transition: border-color 0.2s ease;

  &:active {
    border-color: #007bff;
  }
}

.filter-input-active-min {
  // transition: width 0.2s ease;
  background-color: var(--background-tertiary) !important;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 0px 6px;
  height: 24px !important;
  margin-right: 4px;
  height: 20px;
  width: 60px;
  font-size: 12px;
  color: var(--white);
}

.filter-input-max {
  height: 24px;
  width: 60px;
  font-size: 12px;
  background-color: var(--background-tertiary) !important;
  border: 1px solid var(--grey-accent);
  border-radius: 4px;
  padding: 0px 6px;
  margin-left: 4px;
  transition: border-color 0.2s ease;

  &:active {
    border-color: #007bff;
  }
}

.filter-input-active-max {
  // transition: width 0.2s ease;
  background-color: var(--background-tertiary) !important;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 0px 6px;
  height: 24px !important;
  margin-left: 4px;
  height: 20px;
  width: 60px;
  font-size: 12px;
  color: var(--white);
}

.screenerCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.screenerHeader {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 13px;
}

.screenerHeader.firstColumnHeader {
  position: sticky;
  left: 0;
  // z-index: 100000000;
  background-color: var(--background-secondary);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.screenerHeader.secondColumnHeader {
  position: sticky;
  left: 36px;
  // z-index: 100000000;
  background-color: var(--background-secondary);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.screenerHeader.thirdColumnHeader {
  position: sticky;
  left: 146px;
  padding: 0;
  background-color: var(--grey-accent);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.fixed-width-column0 {
  min-width: 36px !important;
  max-width: 36px !important;
  width: 36px !important;
}

.fixed-width-column1 {
  min-width: 110px !important;
  max-width: 110px !important;
  width: 110px !important;
}

.fixed-width-column2 {
  min-width: 2px !important;
  max-width: 2px !important;
  width: 2px !important;
}

.fixed-width-column3 {
  min-width: 85px !important;
  max-width: 400px !important;
  width: 200px !important;
}

.pagination__item {
  width: 30px;
  height: 24px;
  background: var(--grey-accent);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  &:active {
    border: 2px solid var(--primary-color);
  }
}

.pagination__info {
  width: fit-content;
  height: 28px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
}

.metric-catalog-close-container {
  box-sizing: border-box;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid var(--primary-color);
  }
}

.metric-catalog-close-container-icon {
  width: 24px;
  height: 24px;
}

.metric-catalog-close-container-search {
  color: var(--white);
  background: transparent;
  // borderRadius: 4px;
  border-bottom: 2px solid rgba(215, 215, 215, 0.1);
  border-left: 1px solid rgba(215, 215, 215, 0.1);
  border-right: 1px solid rgba(215, 215, 215, 0.1);
  border-top: 1px solid rgba(215, 215, 215, 0.1);
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 34px !important;
  width: 100%;
  font-size: 16px;
}

.delete-icon {
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
}

// .filter-input-min-max {
//   display: flex;
//   align-items: center;
// }

// .filter-input-min:focus,
// .filter-input-max:focus {
//   border-color: #007bff;
//   outline: none;
// }

// .filter-input-active {
//   border-color: #007bff;
// }

// .filter-input::placeholder {
//   color: #999;
// }

.metric-catalog-container {
  height: 0;
}

.metric-catalog-container.visible {
  height: auto;
}

.institutionalViewWrapper {
  width: 100%;
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.institutionalViewLeft {
  display: flex;
  flex-direction: row;
  height: var(--content-view-height);
  gap: 8px;
}

.institutionalViewContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--content-view-height);
  border-radius: 4px;
  background-color: var(--background-primary);
  padding: 8px;
}

.rightWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 558px);
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 550px;
}

.quarterPanelKeyValueWrapper {
  height: 256px;
  width: 100%;
  padding: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quarterPanelTitle {
  font-size: var(--large-text);
  height: 24px;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quarterPanelPieChart {
  height: 100%;
  width: 100%;
}

.quarterPanelTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.industryPieWrapper {
  width: 100%;
  height: 100%;
}

.industryWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  color: var(--white);
  font-size: var(--default-text);
}

.industries {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

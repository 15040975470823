.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  // flex-direction: column;
  border-radius: 4px;
  gap: 4px;
  // border: 1px solid #939393;
  padding: 4px;
  font-size: var(--large-text);
}

.interval {
  font-weight: bold;
}

.averageReturn {
  font-weight: bold;
}

.rowWrapper {
  display: flex;
  width: 100%;
  gap: 8px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 20px;
  padding: 60px;
}

.colorsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: fit-content;
  height: fit-content;
}

.themes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  width: fit-content;
  height: fit-content;
}

.square {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.colorOptionWrapper {
  display: flex;
  gap: 10px;
  color: var(--white);
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.titleBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.title {
  font-size: 48px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
  line-height: 52px;
}

.subtitle {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: var(--light-grey-accent);
}

.colorOption {
  width: 100%;
  height: 150px;
  border-radius: 4px;
}

.colorsShowCase {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 20px;
  }

  .themes {
    grid-template-columns: repeat(2, 1fr);
  }

  .title {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    padding: 0 30px;
  }
}

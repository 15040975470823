.rc-slider-track {
  background-color: var(--primary-color);
  height: 3px;
}

.rc-slider-rail {
  height: 3px;
}

.rc-slider-handle {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  opacity: 1;
}

.rc-slider-handle:focus,
.rc-slider-handle:active {
  outline: none;
  box-shadow: none;
  border: solid 2px var(--primary-color);
}

.rc-slider-dot {
  border-color: var(--background-primary);
  background-color: var(--background-primary);
}

.rc-slider-mark {
  color: var(--background-primary);
}

.rc-slider-tooltip {
  display: none !important;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: var(--background-secondary);
}

.dataRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  color: var(--off-white);
  white-space: nowrap;
  padding: 0 4px;
  height: 32px;
}

.dataValue {
  color: var(--white);
}

.dataTableWrapper {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // column-gap: 8px;
  display: flex;
  flex-direction: column;
  width: 170px;
}

.pricingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: var(--white);
  background-color: var(--outseek-background);
  width: 100%;
  height: fit-content;
}

.cardWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.card {
  border: 1px solid var(--background-tertiary);
  border-radius: 8px;
  padding: 20px;
  width: 320px;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.proCardGlow {
  box-shadow: 0 0 8px var(--primary-color);
}

.cardHeader {
  display: flex;
  flex-direction: column;
  font-weight: var(--bold-text);
  gap: 4px;
}

.planName {
  width: 100%;
  font-size: 28px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.planDescription {
  height: 40px;
  font-size: 14px;
  color: var(--light-grey-accent);
  font-weight: 400;
}

.planPrice {
  font-size: 38px;
}

.planPriceTerm {
  margin-left: 6px;
  font-size: 15px;
  color: var(--light-grey-accent);
  font-weight: 400;
}

.features {
  margin-bottom: 15px;
  color: var(--light-grey-accent);
}

.featureItem {
  font-size: var(--large-text);
}

.getStartedButton {
  text-align: center;
  background-color: var(--primary-color);
  border: none;
  color: var(--white);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--bold-text);
  width: 100%;
}

.freeButton {
  text-align: center;
  background-color: transparent;
  border: none;
  color: var(--white);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--bold-text);
  border: 2px solid var(--light-grey-accent);
  width: 100%;
}

.selectedPlanButton {
  text-align: center;
  background-color: transparent;
  border: none;
  // color: var(--primary-color);
  color: var(--white);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--bold-text);
  border: 2px solid var(--primary-color);
  width: 100%;
}

.mostPopular {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: var(--bold-text);
  border-radius: 10px;
  position: relative;
  bottom: 16px;
  left: 10px;
}

/// glowing button
.primeButton {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: var(--bold-text);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300%;
    height: 300%;
    background: linear-gradient(
      45deg,
      var(--primary-color),
      var(--purple),
      var(--primary-color),
      var(--background-primary),
      var(--primary-color),
      var(--purple)
    );
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    border-radius: 4px;
  }

  &:active {
    color: var(--white);

    &:after {
      background: transparent;
    }
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-color);
    opacity: 0.7;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@media screen and (max-width: 1100px) {
  .pricingContainer {
    height: fit-content;
  }
  .cardWrapper {
    flex-direction: column-reverse;
    width: 100%;
  }
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%;
  }
}

.discountBanner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: var(--white);
  background-color: var(--primary-color);
  width: fit-content;
  height: fit-content;
  padding: 8px 18px;
  border-radius: 8px;
  font-weight: var(--bold-text);
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.25);
  text-align: center;
}

.typedFont {
  font-size: 24px;
  line-height: 28px;
  height: 36px;
  font-weight: 500;
  color: var(--white);
  width: 100%;
  user-select: none;
  text-align: center;
  margin-bottom: 12px;
  display: inline;
}

.typedFont::after {
  display: inline;
}

.splitEventWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.label {
  width: 140px;
  color: var(--light-grey-accent);
}
.time {
  color: var(--light-grey-accent);
}
.value {
  color: var(--white);
}
.topBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
}

.titleContainer {
  height: fit-content;
  background-color: transparent;
  width: '';
  padding-left: 6px;
  padding-right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-primary);
  border-top: 2px solid var(--button-hover);
  border-left: 2px solid var(--button-hover);
  border-right: 2px solid var(--button-hover);
  border-bottom: 1px solid var(--button-hover);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dragHandle {
  height: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
}

.dragHandle:active {
  cursor: grabbing;
}

.wrapperTest {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.searchButton {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);

  &:hover {
    background-color: rgba(215, 215, 215, 0.15);
  }
}

.searchItemIcon {
  margin: 0;
  cursor: pointer;
  width: 20px;
  height: 14px;
}

.titleText {
  background-color: transparent;

  font-size: var(--default-text);
  font-weight: 400;
  color: var(--white);
  align-items: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
}

.symbolTitle {
  margin: 0;
  font-size: var(--default-text);
}
.closeButton {
  margin: 0;
  cursor: pointer;
  width: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
}

.closeItemIcon {
  border-radius: var(--border-radius);
}

.closeItemIcon:hover {
  background-color: rgba(215, 215, 215, 0.15);
}

.tickerInputWrapper {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 24px;
  padding: 0;
  cursor: pointer;
}

.tickerInput {
  padding-left: 4px;
  width: 50px;
  background-color: transparent;
  border: transparent;
  color: rgb(184, 184, 184);

  font-size: var(--default-text);
  font-weight: 400;
}

.titleButtons {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

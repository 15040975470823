.testimonialsWrapper {
  gap: 20px;
  justify-content: center;
  padding: 40px 20px;
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1100px;
}

.testimonial {
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  width: 100%;

  .quote {
    font-style: italic;
    margin-bottom: 15px;
    color: var(--white);
    font-size: 1.1em;
  }
}

.testimonialText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-align: left;

  .headshot {
    border-radius: 12px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-weight: bold;
      margin-bottom: 5px;
      color: var(--light-grey-accent);
      font-size: 1.2em;
    }

    .occupation {
      font-size: 0.95em;
      color: var(--light-grey-accent);
    }
  }
}

@media (max-width: 900px) {
  .testimonialsWrapper {
    justify-content: center;
    padding: 40px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1100px;
  }

  .testimonial {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;

    .quote {
      max-width: 60%;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 650px) {
  .testimonialsWrapper {
    gap: 40px;
  }
  .testimonial {
    border-radius: 10px;
    padding-bottom: 40px;
    padding: 0;
    text-align: center;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    .quote {
    }
  }
}

.insiderTradingWrapper {
  display: flex;
  flex-direction: column;
  height: var(--view-height);
  width: 100%;
  gap: 8px;
}

.insiderTradingContent {
  display: flex;
  flex-direction: column;
  height: var(--content-view-height);
  width: 100%;
  background-color: var(--background-primary);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  gap: 8px;
}

.tableRosterWrapper {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  border-radius: 4px;
  gap: 8px;
}

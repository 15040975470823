.header {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
  margin: 0;
  padding: 0px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profileDataContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;
  // height: 100%;
  // max-height: 60px;
}

.profileDataContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.data {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
  margin: 0;
  padding: 0px 8px;
  white-space: nowrap;
}

.profileLineBreak {
  width: 100%;
  height: 2px;
  background: var(--background-secondary);
}

.websiteLinkText {
  font-size: var(--default-text);
  color: var(--primary-color);

  &:hover {
    text-decoration: underline;
  }
}

.profileInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.label {
  font-size: var(--large-text);
  color: var(--white);
  font-weight: var(--bold-text);
  display: flex;
  gap: 4px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--background-primary);
}

.settingsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-hover);
  }
}

.oneColumn {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.twoColumns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: auto;
}

.twoColumns > div {
  width: 50%;
  overflow-y: auto;
}

.metricItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 28px;
  background: var(--background-secondary);
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 0px 8px;
  font-size: var(--default-text);
}

.metricItemWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalLabel {
  font-size: var(--default-text);
  color: var(--white);
  font-weight: bold;
  margin-bottom: 4px;
  height: 20px;
}

.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.searchMetricsInput {
  width: 100%;
  height: 28px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 800px;
  height: 508px;
  padding: 12px;
  background-color: var(--background-primary);
  border: 2px solid var(--grey-accent);
  border-radius: 4px;
  color: var(--white);
  gap: 6px;
}

.metricItemContainer {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
}

.metricItemContainerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.BoxArray {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 380px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.modalTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.closeButton {
  border-radius: 4px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  &:hover {
    background-color: var(--button-hover);
  }
}

.selectMetricButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--button-hover);
  }
}

.metricsItemLabel {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  padding: 0px 8px;
  font-size: var(--large-text);
}

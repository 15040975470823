.watchlistNews {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 184px);
  overflow-y: scroll;
  gap: 12px;
}

.newsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 85px;
}

.newsItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.newsItemImg {
  width: 151px;
  margin-right: 10px;
  border-radius: 4px;
}

.newsItemTitle {
  font-size: 16px;
  color: var(--white);
  font-weight: var(--bold-text);
}

.newsItemText {
  font-size: 12px;
  color: var(--light-grey-accent);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsItemSource {
  color: var(--light-grey-accent);
}

.newsItemSymbolWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  color: var(--light-grey-accent);
  font-size: 12px;
}

.newsItemSymbol {
  font-size: var(--large-text);
}

.selectedFundDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  font-size: var(--default-text);
  overflow-y: auto;
}

.CrowdFunding {
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
}

.row {
  width: 100%;
  height: 32px;
  border-bottom: 2px solid var(--background-secondary);

  &:hover {
    background-color: var(--background-secondary);
  }
}

.keyValue {
  padding: 0 4px;
}

.sectionHeader {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  // color: var(--light-grey-accent);
  // padding: 8px;
}

.tableHeader {
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
}

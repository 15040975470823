.toolBar {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  padding: 4px 8px;
  justify-content: space-between;
}

.symbolWrapper {
  display: flex;
  align-items: center;
}

.logo {
  height: 20px;
  user-select: none;
}

.resetTicker {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.resetTicker:hover {
  background-color: var(--background-secondary);
}

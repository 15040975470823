.companyQuote {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
  width: 100%;
  height: 207px;
  background: var(--background-primary);
}

.icon {
  width: 8px;
  height: 8px;
}

.arrowIcon {
  width: 18px;
  height: 22px;
}

.companyQuoteLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 354px;
  height: 100%;
}

.companyQuoteRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  width: 400px;
  height: 100%;
}

.companyQuoteRightHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  width: 400px;
  height: 22px;
}

.quoteText {
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyQuoteRightTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 400px;
  height: 100%;
}

.quoteTickerAndCompany {
  width: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  height: 22px;
  color: var(--white);
}

.quotePrice1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.quotePrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-overflow: ellipsis;
  gap: 4px;
  max-width: 160px;
  color: var(--white);
  font-size: var(--default-text);
  height: 22px;
}

.quoteWrapper {
  display: flex;
  gap: 2px;
  flex-direction: row;
}

.companyQuoteLeftChart {
  width: 354.72px;
  height: 100%;
}

.companyQuoteLeftRange {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
  height: 24px;
}

.rangeItem {
  cursor: pointer;
  font-size: 12px;
  color: var(--white);
  padding: 2px 6px;
  background-color: var(--background-primary);
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--background-secondary);
    transition: 0.3s ease-out;
  }

  &:focus {
    background-color: var(--background-secondary);
  }
}

.roster {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.ownerWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow-y: auto;
  font-size: var(--default-text);
}

.owner {
  width: 100%;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  border-radius: 4px;
  padding: 4px 8px;
  padding-left: 4px;
  cursor: pointer;
  background-color: var(--background-secondary);
  border: 1.5px solid var(--primary-color);
}

.ownerLine {
  width: 100%;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  background-color: var(--background-secondary);
  border: 1.5px solid var(--background-secondary);

  &:hover {
    border: 1.5px solid var(--primary-color);
    transition: 0.1s ease-in-out;
  }
}

.ownerSection {
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.selectedOwnerWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  font-size: var(--default-text);
  overflow: hidden;
  width: 100%;
}

.rosterItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow-y: auto;
}

.ownerType {
  font-size: 12px;
  color: #cecece;
}

.rosterTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
}

.rosterAcqDisp {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
}

.typeOfOwner {
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
}

////// Roster Item styles
///

.rosterItem {
  display: flex;
  width: auto;
  flex-direction: column;
  height: fit-content;
  border-radius: 4px;
  background-color: vaR(--background-secondary);
  padding: 6px 8px;
}

.rosterItemCentered {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: fit-content;
  border-radius: 4px;
  background-color: vaR(--background-secondary);
  padding: 6px 8px;
}

.rosterItemTitle {
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
}

.rosterItemData {
  font-size: 16px;
  font-weight: var(--bold-text);
}

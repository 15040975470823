.companyOverviewWrapper {
  width: 100%;
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.companyOverviewContentWrapper {
  width: 100%;
  height: var(--content-view-height);
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-rows: 40% 60%;
  background-color: var(--background-primary);
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
}

.companyOverviewQuoteProfile {
  width: 100%;
  display: flex;
  border-radius: 4px;
  height: 100%;
  gap: 8px;
}

.companyOverviewChartWrapper {
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 6px;
  display: flex;
  flex-direction: column;
}

.companyOverviewChartQuoteWrapper {
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  display: flex;
}

.companyOverviewChartTimeWrapper {
  width: calc(100% - 280px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.title {
  font-size: var(--large-text);
  color: var(--white);
  font-weight: var(--bold-text);
  display: flex;
  gap: 4px;
  margin-top: 1px;
}

.swapWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-hover);
  }
}

.swap {
  width: 16px;
  height: 16px;
}

.companyOverviewQuote {
  width: 100%;
  display: flex;
  border-radius: 4px;
  background-color: var(--background-primary);
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  gap: 6px;
}

.rangeReturnsWrapper {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.companyOverviewRadarWrapper {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.quoteTable {
  width: 300px;
}

.timeRange {
  position: absolute;
  margin-top: 12px;
  z-index: 10;
}

.companyOverviewProfile {
  width: 100%;
  height: 100%;
  display: flex;
}

.companyOverviewBottomWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 8px;
  height: 100%;
}

.NewsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.bottomRightWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  height: calc(100% - 8px);
}

.label {
  font-size: var(--large-text);
  color: var(--light-grey-accent);
  font-weight: var(--bold-text);
}

.profileWrapper {
  width: 100%;
  height: 37.5%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 8px;
}

.noDataContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--default-text);
  color: var(--light-grey-accent);
}

.dividendTitle {
  font-size: var(--large-text);
  color: var(--white);
  font-weight: var(--bold-text);
  position: relative;
  top: 0;
  left: 0;
}

.radarChartWrapper {
  width: 280px;
  height: 280px;
}

.editButton {
  width: fit-content;
  height: 80%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--default-text);
  color: var(--white);
  font-weight: var(--bold-text);
  padding: 0 8px;
  border-radius: 4px;
  margin-right: -4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.ranges {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 48px;
}

.endButtonWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

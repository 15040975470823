.wrapper {
  padding-top: 8px;
  height: calc(100vh - 184px);
}

.searchWrapper {
  padding-bottom: 8px;
}

.ttmRatiosWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  overflow-y: hidden;
  height: calc(100vh - 202px);
  padding-top: 8px;
  width: 100%;
}

.categoryWrapper {
  overflow-y: scroll;
  height: 100%;
}

.metricWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 28px;
  font-size: var(--default-text);
  gap: 8px;
  padding: 0 6px;
  border: 2px solid transparent;
}

.categoryHeader {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  position: sticky;
  top: 0;
  background-color: var(--background-primary);
}

.metricHeader {
  color: var(--light-grey-accent);
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.metricFooter {
  width: 100%;
  height: 2px;
  background-color: var(--background-secondary);
  border-radius: 4px;
}

.highlight {
  border: 2px solid var(--primary-color);
  border-radius: 4px;
}

.searchInput {
  width: 100%;
  min-width: 200px;
  height: 32px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

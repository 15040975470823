.messageForm {
  display: flex;
  padding: 6px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  gap: 8px;
  height: 48px;
  padding-left: 8px;
  align-items: center;
}

.input {
  flex-grow: 1;
  background-color: var(--background-secondary);
  font-size: var(--large-text);
  border: none;
  outline: none;
  // color: var(--light-grey-accent);
  color: var(--off-white);
}

.sendButton {
  background-color: var(--background-tertiary);
  border: none;
  border-radius: 4px;
}

.logo {
  height: 26px;
  user-select: none;
}

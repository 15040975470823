.comparisonContainer {
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  border: 2px solid var(--background-tertiary);
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.sections {
  display: flex;
  flex-direction: column;
  width: calc(100% - 486px);
  gap: 8px;
  height: 100%;
}

.metricBar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-tertiary);
  max-width: 100%;
  overflow: hidden;
  border-radius: 4px;
  padding: 4px;
}

.metricValues {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comparisonRowHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border-bottom: 2px solid var(--background-secondary);
  padding: 6px 6px;
  background-color: var(--background-secondary);
  border-radius: 4px;
}

.comparisonRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 6px 6px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  width: 100%;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.metricBarWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.borderline {
  width: calc(100% - 8px);
  height: 2px;
  background-color: var(--background-secondary);
  border-radius: 8px;
}

.metricHeader {
  white-space: nowrap;
  min-width: 200px;
}

.dataWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
}

.dataWrapperHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-right: 12px;
}

.analyticValue {
  min-width: 65px;
  text-align: right;
  white-space: nowrap;
}

.companyValue {
  min-width: 65px;
  text-align: right;
  white-space: nowrap;
}

.difference {
  min-width: 65px;
  text-align: right;
  white-space: nowrap;
}

.chartDiv {
  width: 100%;
}

.expandedPanelLayout {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
}

.chartWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.insideChartWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.scaleComp {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: fit-content;
}

.bottomWrapper {
  width: 100%;
  height: 100%;
}

.radarChartWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.scoresWrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
}

.overAllSection {
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
}

.comparisonTable {
  width: 460px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.categoryHeader {
  display: flex;
  width: 452px;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-bottom: 2px solid var(--background-secondary);
  padding: 6px 8px;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--bold-text);
}

.metricContainer {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}

.metricContainerClosed {
  max-height: 0;
}

.nullScaleComp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: var(--large-text);
  color: var(--off-white);
  user-select: none;
}

.listWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.dataSwapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0px 8px;
  height: 28px;
  border-radius: 4px;
  background-color: var(--background-tertiary);
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease-in-out;
  bottom: 132px;
  position: absolute;
  z-index: 100;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.buttonWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: calc(100% - 170px);
}

.searchMetricsInput {
  width: 100%;
  height: 28px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.searchBar {
  margin-bottom: 8px;
}

.dataRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  color: var(--off-white);
  white-space: nowrap;
  padding: 0 8px;
  height: 32px;
  font-size: var(--large-text);
}

.dataValue {
  color: var(--white);
}

.divider {
  width: 100%;
  height: 2px;
  background-color: var(--background-secondary);
  border-radius: 8px;
}

.allScoresWrapper {
  overflow-y: auto;
}

.transcriptWrapper {
  height: 384px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.transcriptToolBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  z-index: 10;
}

.buttonWrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.transcriptToolBarItem {
  font-size: var(--large-text);
  line-height: 24px;
  height: 100%;
  margin: 0;
  font-weight: var(--bold-text);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.transcriptToolBarItemWrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.transcriptToolBarItemSelect {
  font-size: var(--large-text);
  line-height: 24px;
  height: 100%;
  margin: 0;
  color: var(--white);
}

.contentContainer {
  width: 100%;
  height: 100%;
  font-size: var(--large-text);
  overflow-y: scroll;
  color: var(--white);
  text-align: left;
}

.paragraphText {
  color: var(--white);
  padding-bottom: 14px;
}

.operatorText {
  color: var(--primary-color);
  font-weight: 600;
}

.textToSpeechButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
  background-color: var(--background-primary);
}

.contentContainerWrapper {
  width: 100%;
  height: 344px;
  font-size: 14px;
  color: var(--white);
  text-align: left;
}

.closeButton {
  margin: 0;
  cursor: pointer;
  width: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
}

.closeItemIcon {
  border-radius: var(--border-radius);
}

.closeItemIcon:hover {
  background-color: rgba(215, 215, 215, 0.15);
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.modalTitle {
  color: var(--white);
}

.modalConfirmButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modalOption {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.transcriptOptionsSelect {
  width: 100px;
  color: var(--white);
}

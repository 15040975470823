.presetButton {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);
  color: var(--light-grey-accent);
  border-radius: 4px;
  font-size: var(--default-text);
  font-weight: var(--bold-text);
}

.PresetPanel {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
  width: 100%;
  height: 24px;
}

.PresetPanel-content {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
}

.arrow-button {
  background: var(--grey-accent);
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px;
  height: 24px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
  border: none;
  outline: none;
  color: var(--white);

  &:active {
    border: 2px solid var(--primary-color);
  }
}

.arrow-button-gone {
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.boxLeft {
  position: absolute;
  left: 30px;
  width: 60px;
  height: 24px;
  background: linear-gradient(to left, transparent, var(--background-primary));
}

.boxRight {
  position: absolute;
  right: 30px;
  width: 60px;
  height: 24px;
  background: linear-gradient(to right, transparent, var(--background-primary));
}

.filter-input-button {
  background: var(--grey-accent);
  font-size: var(--default-text);
  border-radius: 4px;
  cursor: pointer;
  padding: 0 6px;
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
  border: none;
  outline: none;
  color: var(--white);
  border: 2px solid var(--grey-accent);

  &:active {
    border: 2px solid var(--primary-color);
  }
}

.marketMoverTableWrapper {
  height: 100%;
}

.marketMoverTitleTableWrapper {
  background-color: var(--background-primary);
  border-radius: 4px;
}

.marketMoversWrapper {
  height: 100%;
  width: 290px;
  display: flex;
  overflow: hidden;
  gap: 12px;
  border-radius: 4px;
  background-color: var(--background-primary);
}

.marketMoversPanel {
  height: 100%;
}

$FullBlockPadding: 150px;
$HalfBlockPadding: 75px;
$HundredGap: 100px;
$TwentyGap: 20px;

/////// BODY ///////
body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

/////// WRAPPER ///////

.desktop-large-view-homepage-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--outseek-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktopContentWrapper {
  width: 100%;
  max-width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/////// SPACER ///////

.desktop-large-view-homepage-spacer {
  width: 100%;
  height: 100vh;
}

/////// CONTENT ONE ///////

.desktop-large-view-homepage-content-1 {
  width: 100%;
  // max-width: 1800px;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center ;
  align-items: center;
  padding: 0 $FullBlockPadding;
  padding-top: 150px;
  gap: 30px;
  z-index: 1000;
}

.desktop-large-view-homepage-content-1-dashboard-img-top {
  width: 100%;
  height: 100%;
  z-index: 100;
  // margin-left: 10%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.desktop-large-view-homepage-content-1-col-2 {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 60px;
}

.desktop-large-view-homepage-content-1-enter-terminal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.desktop-large-view-homepage-content-1-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}

.desktop-large-view-homepage-content-1-col-1 {
  width: 40%;
  position: relative;
}

.desktop-large-view-homepage-content-1-col {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.desktop-large-view-homepage-content-1-content-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-large-view-homepage-content-1-content-row-2 {
  z-index: 100;
  margin-top: 30px;
}

.desktop-large-view-homepage-content-1-title-font {
  user-select: none;
  font-size: 78px;
  color: var(--white);
  font-weight: var(--bold-text);
  line-height: 1;
  letter-spacing: -1px;
  text-shadow: 0 0 15px rgb(192 219 255 / 30%), 0 0 0px rgb(65 120 255 / 20%);
  color: var(--white);
  pointer-events: none;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}

/////// CONTENT TWO ///////

.desktop-large-view-homepage-content-2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $HundredGap;
}

.desktop-large-view-homepage-content-2-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;
  height: 140px;
}

.desktop-large-view-homepage-content-2-col-1-title {
  font-size: 28px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-2-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-2-col-2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.desktop-large-view-homepage-content-2-img {
  width: 100%;
  height: 100%;
  border: 1.5px solid #606060;
  border-radius: 4px;
  box-shadow: 0 0 4px 0px #606060;
}

/////// CONTENT TWO ///////

.desktop-large-view-homepage-content-3 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $HundredGap;
}

.desktop-large-view-homepage-content-3-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;
  height: 140px;
}

.desktop-large-view-homepage-content-3-col-1-title {
  font-size: 28px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-3-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-3-col-2 {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.desktop-large-view-homepage-content-3-img {
  width: 100%;
  height: 500px;
}

/////// CONTENT FOUR ///////

.desktop-large-view-homepage-content-4 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $FullBlockPadding;
  gap: $HundredGap;
}

.desktop-large-view-homepage-content-4-col-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;
  height: 140px;
}

.desktop-large-view-homepage-content-4-col-1-title {
  font-size: 28px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-4-col-1-subtitle {
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.desktop-large-view-homepage-content-4-col-2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: $TwentyGap;
}

.desktop-large-view-homepage-content-4-img {
  width: 100%;
  height: 500px;
}

/////// CONTENT Five ///////

.desktop-large-view-homepage-content-5 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 68px;
  gap: 30px;
}

.desktop-large-view-homepage-content-5-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $TwentyGap;
}

.desktop-large-view-homepage-content-5-col-1-title {
  font-size: 56px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $FullBlockPadding;
  white-space: nowrap;
}

.desktop-large-view-homepage-content-5-col-1-subtitle {
  font-size: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.desktop-large-view-homepage-content-5-img {
  width: 50%;
  height: 50%;
}

/////// CONTENT SIX ///////

.desktop-large-view-homepage-content-6 {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $FullBlockPadding;
  gap: 30px;
}

.desktop-large-view-homepage-content-6-column-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.desktop-large-view-homepage-content-6-col-title-2 {
  font-size: 56px;
  color: var(--white);
  font-weight: 500;
  line-height: 1;
  padding: 0;
  text-align: center;
}

//////////

.container0 {
  width: 100vw !important;
  position: relative;
  height: 125vh !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0 $FullBlockPadding;
  padding-top: 150px;
  gap: $TwentyGap;
  z-index: 1000;
}

.desktop-large-view-homepage-col-2 {
  position: relative;
  flex-basis: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 60px;
}

.desktop-large-view-homepage-title-bar {
  width: 10px;
  height: 55%;
  border-radius: 4px;
  background-color: var(--outseek-background);
  position: absolute;
  left: -35px;
  top: 8px;
}

.desktop-large-view-homepage-enter-terminal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.desktop-large-view-homepage-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}

.container1 {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--outseek-background);
  flex-direction: column;
  width: 100%;
}

/////// Panel Two ///////

.desktop-large-view-homepage-panel-feature {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: minmax(0px, 1fr);
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-template-rows: repeat(2, minmax(0px, 1fr));
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--white);
  box-sizing: border-box;
  background-color: var(--outseek-background);
  padding: 100px;
  gap: 20px;
}

.desktop-large-small-spacer {
  width: 100%;
  // height: 50px;
}

.featureContainer {
  width: 100%;
  height: 100vh;
  padding: 168px 100px;
  box-sizing: border-box;
}

//// Media Queries ////

@media screen and (min-width: 1080px) and (max-width: 1399px) {
  .desktop-large-view-homepage-content-2 {
    padding: 0 $HalfBlockPadding;
  }
  .desktop-large-view-homepage-content-3 {
    padding: 0 $HalfBlockPadding;
  }
  .desktop-large-view-homepage-content-4 {
    padding: 0 $HalfBlockPadding;
  }
  .desktop-large-view-homepage-content-2-col-1-subtitle {
    font-size: 16px;
  }
  .desktop-large-view-homepage-content-3-col-1-subtitle {
    font-size: 16px;
  }
  .desktop-large-view-homepage-content-4-col-1-subtitle {
    font-size: 16px;
  }
}

.copilotFeatures {
  height: 100%;
  width: 100%;
  color: var(--white);
  background-color: var(--outseek-background);
  padding: 100px 50px;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  .copilotTitle {
    font-size: 56px;
    font-weight: var(--bold-text);
    line-height: 115%;
    user-select: none;
    padding: 0 20px;
    width: 100%;
    max-width: 1000px;
  }

  .copilotPill {
    width: fit-content;
    height: fit-content;
    background-color: var(--background-primary);
    color: var(--primary-color);
    border-radius: 20px;
    padding: 8px 16px;
    font-weight: var(--bold-text);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  border-radius: 12px;
  gap: 20px;
}

.imageWrapper {
  max-width: 1100px;
  width: 100%;
}

.image {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 10px 0px var(--primary-color);
  border: 1px solid var(--primary-color);
}

.stats {
  display: flex;
  gap: 60px;
}

.stat {
  text-align: center;
}

.statValue {
  font-size: 42px;
  line-height: 130%;
  font-weight: bold;
  color: var(--primary-color);
}

.statLabel {
  font-size: 18px;
  font-weight: var(--bold-text);
  color: var(--light-grey-accent);
}

@media (max-width: 1100px) {
  .stats {
    flex-direction: column;
    gap: 20px;
  }
  .title {
    flex-direction: column;
    gap: 20px;
  }
  .wrapper {
    grid-template-columns: 1fr;
  }

  .copilotTitle {
    font-size: 42px;
    font-weight: var(--bold-text);
    line-height: 115%;
    user-select: none;
    width: 100%;
    max-width: 600px;
    padding: 0;
  }
}

@media (max-width: 900px) {
  .copilotFeatures {
    padding: 100px 20px;
    gap: 20px;
  }
  .stats {
    flex-direction: column;
    gap: 20px;
  }
  .title {
    flex-direction: column;
    gap: 20px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .copilotTitle {
    font-size: 36px !important;
    padding: 0;
    max-width: 400px;
  }

  .image {
    border-radius: 4px;
    box-shadow: 0 0 4px 0px var(--primary-color);
  }
}

@media (max-width: 450px) {
  .copilotTitle {
    font-size: 28px !important;
    padding: 0;
  }
}

.overlay {
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  width: fit-content;
  z-index: 10000;
  padding: 4px 4px 4px 4px;
  margin: 0;
  height: fit-content;
  border-radius: var(--border-radius);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.pulsingCircle {
  animation: pulse 2s infinite;
}

.tooltip {
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  width: fit-content;
  z-index: 10000;
  padding: 4px 4px 4px 4px;
  margin: 0;
  height: fit-content;
  border-radius: var(--border-radius);
  position: absolute;
  color: var(--white);
  font-size: 12px;
  pointer-events: none;
}

.summaryWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  color: var(--white);
}

.bulletPoints {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  font-size: var(--large-text);
  gap: 8px;
}

.summarizeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
}

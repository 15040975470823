.breakdownConatiner {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.breakdownToolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  gap: 12px;
}
.DropDownWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.functionButton {
  cursor: pointer;
  border-radius: var(--border-radius);
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.text {
  color: var(--white);
  font-size: var(--large-text);
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 0;
}

// Tool Tip

.customized-tooltip-content {
  padding: 6px;
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  height: fit-content;
  width: auto;
  z-index: 10000;
  border-radius: var(--border-radius);
}

.total {
  font-size: 10.5px;
  color: var(--white);
}

.listItem {
  color: var(--white);
  font-size: 10.5px;
  display: flex;
  flex-direction: column;
}

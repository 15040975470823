.searchInput {
  width: 100%;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 1px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

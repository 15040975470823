.compChartContainer {
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.container {
  padding: 0;
}

.tab {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}
.metric-catalog-input {
  color: var(--white);
  background: transparent;
  border-bottom: 2px solid rgba(215, 215, 215, 0.1);
  border-left: 1px solid rgba(215, 215, 215, 0.1);
  border-right: 1px solid rgba(215, 215, 215, 0.1);
  border-top: 1px solid rgba(215, 215, 215, 0.1);
  padding-left: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.comparison-charts-metric-catalog-container {
  color: var(--white);
  position: absolute;
  z-index: 999;
  margin-top: -16px;
  background: var(--background-primary) !important;

  width: 30%;
  user-select: none;
}

.comparison-charts-metric-catalog-container {
  overflow: hidden;
}

.slide-down-enter {
  max-height: 0;
  opacity: 0;
}

.slide-down-enter-active {
  max-height: 600px; // You may need to adjust this value to fit the full content.
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-down-exit {
  max-height: 600px;
  opacity: 1;
}

.slide-down-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.metric-catalog-category-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.metric-catalog-button {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: var(--bold-text);
}

.catalog-title-bar {
  display: flex;
}

.metric-catalog-btn-container {
  color: var(--white);
  font-size: 13.75px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background: rgba(215, 215, 215, 0.1);
  }
}

.metric-catalog-category-label {
  border-bottom: 1px solid var(--white);
}

.metric-catalog-category-label {
  color: var(--white);
}

.metric-catalog-container-title {
  position: fixed;
  display: flex;
  background: var(--background-primary);
  align-items: center;
  height: 32px;
  padding: 0 8px;
  flex-direction: row;
  cursor: pointer;
  border: 2px solid var(--button-hover);

  &:hover {
    background-color: var(--button-hover);
  }
}

.metric-catalog-category-list-item {
  padding: 4px 8px;
  height: fit-content;
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // background-color: blue;

  &:hover {
    // background-color: rgba(215, 215, 215, 0.1);
    cursor: pointer;
  }
}

.metric-catalog-category-list {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.metric-catalog-category-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  // padding: 8px;
  column-gap: 8px;
  // display: grid;
  // grid-template-columns: auto auto;
}

.metric-catalog-expand-arrow {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metric-catalog-category-container {
  width: 100%;
  margin-top: 40px;
  margin-bottom: -40px;
}

.metric-name {
  font-size: 12px;
  color: var(--white);
  padding: 4px 4px;
  padding-right: 2px;
  white-space: nowrap;
}

.comparison-chart-metric-tag-close-icon {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: rgba(215, 215, 215, 0.1);
  }
}

.comparison-chart-metric-tag {
  background-color: transparent;
  width: fit-content;
  height: 28px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
}

.charts-tool-bar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.charts-tool-bar-wrapper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.charts-tool-bar-item {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.comparison-chart-metrics {
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-direction: row;
  gap: 8px;
  min-height: 32px !important ;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  color: var(--white);
  user-select: none;
  font-size: 13px;
  white-space: nowrap;
}

.checkbox {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid var(--white);
  cursor: pointer;
}

.MetricTabDivider {
  width: 100%;
  height: 2px;
  background-color: var(--button-hover);
  margin-left: 8px;
}

//sketch tab styles below... /// warning: this is a mess

.react-tabs {
  margin-top: 32px;
  display: flex;
  border-radius: 0 0 4px 4px;
  width: 100%;
  height: 90%;
  border-left: 2px solid var(--button-hover);
  border-right: 2px solid var(--button-hover);
  border-bottom: 2px solid var(--button-hover);
  color: var(--white);
  background: var(--background-primary);
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  margin: 0 !important;
  padding: 0;
  border-bottom: none !important;
  color: var(--white);
  background: var(--background-primary);
  border-right: 2px solid var(--button-hover);
}

.Tabs-label {
  margin: 0;
}

.react-tabs__tab {
  height: 32px;
  display: flex;
  align-items: center;
  list-style: none;
  cursor: pointer;
  color: #bbb;
  padding: 4px 8px;
}

.react-tabs__tab--selected {
  background: var(--button-hover);
  border-color: #1c1d1f;
  border-left: 2px solid var(--primary-color);
  color: var(--white);
}

.react-tabs__tab-panel {
  display: none;
  width: 95%;
  overflow-y: scroll;
  padding: 4px 4px;
}

.react-tabs__tab-panel::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 12px;
}

.react-tabs__tab-panel::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}
.react-tabs__tab-panel::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
}

.react-tabs__tab-panel--selected {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(100, fit-content(100%));
}

.panel-content {
  text-align: center;
}

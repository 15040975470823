.menuItemTooltip {
  color: transparent;
  width: 100%;
  white-space: nowrap;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--button-padding);
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--white);
  font-size: var(--default-text);
  font-weight: 500;
  gap: 4px;
  &:hover {
    background-color: var(--button-hover);
    transition: ease 0.1s;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

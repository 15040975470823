.authPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: var(--outseek-background);
}

.authViewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--view-height);
  width: 100%;
  background-color: var(--outseek-background);
}

.authPageWrapperModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  background-color: var(--outseek-background);
}

.authWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: fit-content;
  background-color: var(--background-primary);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: var(--off-white);
  gap: 16px;
  border-radius: 4px;
}

.signInUpWrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 32px;
}

.orWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: var(--off-white);
  user-select: none;
}

.line {
  width: 100%;
  height: 0.5px;
  background-color: var(--off-white);
}

.tryDemo {
  width: 100%;
  height: fit-content;
  padding: 12px;
  color: var(--white);
  font-weight: var(--bold-text);
  background-color: transparent;
  border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--background-tertiary);

  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: translateY(-2px);
  }
}

.authTabList {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.authTabListItem {
  width: 100%;
  height: 60px;
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  background-color: var(--background-secondary);
  cursor: pointer;
}

.authTabListItemSelected {
  width: 100%;
  height: 60px;
  font-size: 18px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  background-color: transparent;
  border-top: 4px solid var(--primary-color);
  cursor: pointer;
}

.styles.authLabel {
  color: var(--white);
  font-size: 16px;
  font-weight: var(--bold-text);
  margin-bottom: 8px;
}

.authInput {
  border-radius: 6px;
  border: 2px solid var(--white);
  background-color: transparent;
  padding: 12px;

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.authNameInput {
  border-radius: 6px;
  border: 2px solid var(--white);
  background-color: transparent;
  padding: 12px;
  width: 100%;

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.authConfirmButton {
  width: 100%;
  height: fit-content;
  padding: 12px;
  color: var(--white);
  font-weight: var(--bold-text);
  background-color: var(--primary-color);
  border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: translateY(-2px);
  }
}

.showSignIn {
  color: var(--primary-color);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.transparentButton {
  background-color: transparent;
  color: var(--white);
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotPassword {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -8px;
  margin-top: -3px;
  &:hover {
    text-decoration: underline;
  }
}

.transparentButtonSmall {
  background-color: transparent;
  color: var(--light-grey-accent);
  font-size: 11px;
  text-align: center;
}

.tosPP {
  font-size: 11px;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
}

.authHeader {
  width: 100%;
  font-size: 24px;
  color: var(--white);
  text-align: center;
  font-weight: var(--bold-text);
  margin-bottom: 8px;
}

.authError {
  width: 100%;
  font-size: 14px;
  color: var(--red);
  font-weight: var(--bold-text);
  margin-top: -8px;
  padding-left: 8px;
}

@media (max-width: 768px) {
  .authPageWrapperModal .authWrapper {
    width: 100%;
    height: 100%;
  }

  .signInUpWrapper {
    width: 100%;
    padding: 20%;
  }

  .authTabListItem,
  .authTabListItemSelected {
    font-size: 14px;
  }

  .authInput {
    padding: 8px;
  }

  .authConfirmButton {
    padding: 8px;
  }

  .authHeader {
    font-size: 20px;
  }
}

@media (max-width: 460px) {
  .authPageWrapperModal .authWrapper {
    width: 100%;
    height: 100%;
  }

  .signInUpWrapper {
    width: 100%;
    padding: 40px;
  }

  .authTabListItem,
  .authTabListItemSelected {
    font-size: 14px;
  }

  .authInput {
    padding: 8px;
  }

  .authConfirmButton {
    padding: 8px;
  }

  .authHeader {
    font-size: 20px;
  }
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.swapWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-hover);
  }
}

.swapIcon {
  width: 16px;
  height: 16px;
}

.pricingView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: var(--white);
  background-color: var(--outseek-background);
  width: 100%;
  height: fit-content;
  min-height: 100vh;
}

.pricingContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 150px;
  gap: 40px;
}

.pricingHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  color: var(--white);
}

.pricingTitle {
  font-size: 58px;
  font-weight: var(--bold-text);
  line-height: 1.2;
  text-align: center;
  margin-bottom: 8px;
}

.pricingSubheader {
  font-size: 18px;
  font-weight: var(--bold-text);
  color: var(--white);
  text-align: center;
}

.quote {
  font-size: 18px;
  color: var(--light-grey-accent);
}

.breakdownWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

$breakpoint-tablet: 1100px;
$breakpoint-mobile: 480px;

@media (max-width: $breakpoint-tablet) {
  .pricingContent {
    padding: 20px 75px;
  }
  .pricingTitle {
    font-size: 42px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .pricingContent {
    padding: 20px 35px;
  }
  .pricingTitle {
    font-size: 36px;
  }
}

.featureWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 400px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 12px;
}

.featureTitle {
  font-size: 24px;
  font-weight: var(--bold-text);
  color: var(--white);
  text-align: center;
  width: 100%;
}

.featureSubtitle {
  font-size: 16px;
  color: var(--light-grey-accent);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 6px;
  text-align: center;
}

.headerWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px 30px;
  padding-bottom: 0;
  gap: 12px;
}

.globalFeatures {
  display: flex;
  justify-content: center;
  gap: 100px;
  width: 100%;
  height: 100%;
  padding: 0px 40px;
}

.statBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.box2 {
  flex-direction: row;
  gap: 0;

  .statsWrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 260px;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
}

.box3 {
  flex-direction: column;
}

.box4 {
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.box5 {
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.worldMap {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: -150px;
  user-select: none;
}

.statsItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 33%;
}

.stat {
  text-align: center;
}

.statValue {
  font-size: 68px;
  line-height: 130%;
  font-weight: bold;
  color: var(--primary-color);
}

.statLabel {
  font-size: 16px;
  color: var(--light-grey-accent);
  text-align: center;
}

.carouselContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 120px;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      to bottom,
      var(--outseek-background),
      rgba(0, 0, 0, 0)
    );
  }

  &::after {
    bottom: 0;
    background: linear-gradient(
      to top,
      var(--outseek-background),
      rgba(0, 0, 0, 0)
    );
  }
}

.carouselContainerHorizontal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      var(--outseek-background),
      rgba(0, 0, 0, 0)
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      var(--outseek-background),
      rgba(0, 0, 0, 0)
    );
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  animation: scroll 25s linear infinite;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.listHorizontal {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  animation: scrollHorizontal 100s linear infinite;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.listItem {
  padding: 12px 20px;
  border-radius: 20px;
  border: 1px solid var(--grey-accent);
  color: var(--light-grey-accent);
}

.listItemHorizontal {
  padding: 12px 20px;
  border-radius: 20px;
  border: 1px solid var(--grey-accent);
  color: var(--light-grey-accent);
  min-width: 210px;
}

@media (max-width: 1100px) {
}

@media (max-width: 900px) {
  .headerWrapper {
    padding: 8px 20px;
  }
  .box2 {
    flex-direction: column;
    .statsWrapper {
      border-left: none;
    }
    height: 560px;
  }

  .globalFeatures {
    flex-direction: column;
    gap: 4px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
  }

  .box4 {
    height: 400px;
    gap: 0px;
  }

  .statBox {
    padding: 10px;
  }

  .statValue {
    line-height: 130%;
  }
}

.outseekWrapper {
  display: flex;
  flex-direction: column;
  top: 0;
  overflow: hidden;
}

.toolTabGridWrapper {
  display: flex;
}

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

.contentWrapper {
  padding: 8px;
  border-top-left-radius: 8px;
  background-color: var(--outseek-background);
}

.layout {
  width: 4000px;
  height: 4000px !important;
  z-index: 1;
  overflow-y: hidden;
}

.gridLayoutWrapper {
  height: var(--view-height);
  overflow-y: scroll;
  overflow-x: scroll;
}

.viewWrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 8px;
  background-color: var(--outseek-background);
}

.gridLayoutWrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 12px;
}

.gridLayoutWrapper::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  opacity: 0.5;
  border-radius: 6px;
}
.gridLayoutWrapper::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

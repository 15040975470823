.copilotWrapper {
  height: var(--view-height);
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
  color: var(--white);
}

.chatWindow {
  height: 100%;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 8px;
}

.messages {
  flex: 1;
  // padding-right: 8px;
  margin-bottom: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

.toggleButton {
  background-color: var(--background-tertiary);
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  color: var(--off-white);
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: var(--background-secondary);
}

.postItInput {
  width: 100%;
  height: 100%;
  background: var(--background-secondary);
  color: var(--white);

  font-size: 16px;
  line-height: 100%;
  border: transparent;
}

.waterfallComponentWrapper {
  height: calc(100vh - 154px);
  padding-top: 8px;
}

.waterfallWrapper {
  height: calc(100vh - 180px);
}

.buttonWrapper {
  display: flex;
  gap: 4px;
  height: 32px;
  align-items: center;
}

.changeDataFrequencyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
}

.dataFrequencyButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  //   width: 28px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}

.divider {
  width: 1px;
  height: 60%;
  background-color: var(--grey-accent);
}

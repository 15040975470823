.tableKeyValueLabel {
  margin-right: 16px;
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--light-grey-accent);
}

.tableKeyValueHorizontalFlex {
  display: flex;
  height: fit-content;
  width: 100%;
  font-size: var(--default-text);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  color: var(--white);
  padding: 0 8px;
}

.tableKeyValueData {
  margin-right: 4px;
  font-weight: var(--bold-text);
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tableKeyValueContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--white);
  height: 100%;
}

.tableKeyValueRow {
  display: flex;
  justify-content: space-between;
}

.emptySpace {
  width: 16px;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: var(--background-secondary);
}

.tableKeyItem {
  display: flex;
  flex-direction: column;
  width: 50%;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.chartWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background-color: var(--background-secondary);
  color: var(--white);
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--default-text);
  cursor: pointer;
}

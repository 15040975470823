.secFillings {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.contentWrapper {
  display: flex;
  gap: 8px;
  height: var(--content-view-height);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
}

.recentFilingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.recentFilingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
}

.recentFilings {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: fit-content;
  width: 100%;
  border: 2px solid var(--background-tertiary);
  border-radius: 4px;
  padding: 8px;
  font-size: var(--large-text);
}

.filingTitle {
  width: 100%;
  color: var(--white);
}

.recentFiling {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
}

.filingDate {
  color: var(--light-grey-accent);
}

.statsCard {
  display: flex;
  gap: 8px;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  padding: 8px;
  font-size: var(--large-text);
}

.label {
  color: var(--light-grey-accent);
}

.value {
  color: var(--white);
  font-weight: var(--bold-text);
}

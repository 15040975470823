.sankeyComponentWrapper {
  height: calc(100vh - 154px);
  padding-top: 8px;
  gap: 8px;
}

.chartWrapper {
  height: calc(100vh - 168px);
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 32px;
  align-items: center;
  font-size: var(--large-text);
}

.paddingRange {
  width: 100px;
  height: 8px;
  background: var(--grey-accent);
  border-radius: 5px;
  outline: none;

  -webkit-appearance: none;
}

.paddingRange::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;

  -webkit-appearance: none;
}

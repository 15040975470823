.grid-circle {
  fill: none;
  stroke: var(--grey-accent);
}

.axis-line {
  stroke: var(--grey-accent);
  stroke-width: 1px;
}

.radar-area {
  fill: none;
  stroke-width: 2px;
}

.radar-dot {
  fill: var(--white);
  stroke-width: 2px;
}

.customized-tooltip-content-core-radar {
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  width: fit-content;
  z-index: 10000;
  padding: 4px 4px 4px 4px;
  margin: 0;
  height: fit-content;
  border-radius: var(--border-radius);
  font-size: 13px;
  color: var(--white);
}

.axis-label {
  font-weight: 500;
  fill: var(--off-white);
  letter-spacing: 0.2px;
}

.slice-path {
  transition: fill 0.1s ease-in-out;
}

.radarChartBoxedModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: fit-content;
  background-color: var(--background-primary);
  padding: 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
  font-size: var(--default-text);
}

.radarChartTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
}

.radarChartCloseModal {
  cursor: pointer;
  padding: 1px;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.paymentSuccessWrapper {
  height: fit-content;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: var(--outseek-background);
  color: var(--white);
  gap: 20px;
  font-size: 20px;
  text-align: center;
}

.enterTerminal {
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: var(--bold-text);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300%;
    height: 300%;
    background: linear-gradient(
      45deg,
      var(--primary-color),
      var(--purple),
      var(--primary-color),
      var(--background-primary),
      var(--primary-color),
      var(--purple)
    );
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    border-radius: 4px;
  }

  &:active {
    color: var(--white);

    &:after {
      background: transparent;
    }
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-color);
    opacity: 0.7;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

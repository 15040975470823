.modalOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--light-grey-accent);
  width: 400px;
  background-color: var(--background-primary);
  border: 2px solid var(--background-tertiary);
  padding: 16px;
  border-radius: 4px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  font-size: var(--large-text);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: var(--bold-text);
  color: var(--white);
}

.closeButton {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--background-tertiary);
  }
}

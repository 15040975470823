.econEventWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;

  .eventName {
    font-size: var(--large-text);
    font-weight: var(--bold-text);
    color: var(--white);
  }
  .bottomWrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .eventDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
  .info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .label {
    width: 60px;
    color: var(--light-grey-accent);
  }
  .time {
    color: var(--light-grey-accent);
  }
  .value {
    width: 100%;
    color: var(--white);
  }
}

.errorBoundaryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--view-height);
  z-index: 1000;
  background: var(--outseek-background);
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.errorBoundaryInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.errorBoundaryInfoText {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
}

.errorBoundaryInfoTitle {
  font-size: 28px;
  font-weight: 600;
  color: var(--white);
}

.linksWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 80px;
  border-radius: 8px;
  border: 1px solid var(--background-tertiary);
  transition: all 0.2s ease;
  gap: 4px;

  &:hover {
    background-color: var(--background-primary);
  }
}

.linkText {
  font-size: 14px;
  color: var(--white);
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 24px;
  gap: 24px;
  color: var(--white);
  text-decoration: none;
}

.fourTitle {
  font-size: 48px;
  font-weight: 600;
  color: var(--white);
}

.code {
  color: var(--primary-color);
}

@media (max-width: 1000px) {
  .errorBoundaryContainer {
    padding: 20px;
  }
  .linksWrapper {
    grid-template-columns: 1fr 1fr;
  }
  .errorBoundaryInfo {
    flex-direction: column;
    gap: 20px;
  }
  .errorBoundaryInfoText {
    text-align: center;
  }
  .errorBoundaryInfoTitle {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .errorBoundaryContainer {
    padding: 30px;
  }
  .linksWrapper {
    grid-template-columns: 1fr;
  }
  .errorBoundaryInfoText {
    text-align: center;
    font-size: 18px;
  }
  .errorBoundaryInfoTitle {
    text-align: center;
    font-size: 26px;
  }
}

.descriptionPanel::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.descriptionPanel::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}
.descriptionPanel::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

.profileContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
  margin: 0;
  padding: 0px 8px;
}

.profileDataContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  min-width: 200px;
  width: 100%;
  padding: 2px 0px;
}

.data {
  font-weight: var(--bold-text);
  font-size: var(--default-text);
  color: var(--white);
  margin: 0;
  padding: 0px 8px;
}

.profileLineBreak {
  width: 100%;
  height: 2px;
  background: var(--background-secondary);
}

.profileCompanyName {
  font-style: normal;
  font-size: 14px;
  color: var(--white);
  margin: 0;
}

.secondPanel {
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 98px;
}

.websiteLinkText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--primary-color);

  &:hover {
    text-decoration: underline;
  }
}
.profileLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 98px;
  width: 98px !important;
  min-width: 98px;
  background-color: rgb(116, 116, 116);
  border-radius: 4px;
}
.companyImage {
  height: 86px;
  width: 86px;
}

.content {
  display: flex;
  flex-direction: column;
}

.contentColumn1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
}
.contentColumn2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profileInfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
}

.firstPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: fit-content;
}

.descriptionPanel {
  height: 158px;
  overflow-y: scroll;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(26, 33, 41, 1) 0%,
    var(-background-primary) 100%
  );
}

.profileDescription {
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  padding-right: 2px;
  font-size: var(--default-text);
  color: var(--white);
}

.tagContainer {
  width: fit-content;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  border-radius: var(--border-radius);
  padding: var(--button-padding);
  gap: 4px;
}

.tagText {
  font-size: var(--default-text);
  white-space: nowrap;
  margin: 0;

  color: var(--white);
  cursor: default;
}

.category {
  font-size: var(--default-text);
}

.watchlist {
  display: flex;
  flex-direction: column;
  height: var(--view-height);
  width: 100%;
  border-radius: 4px;
  color: var(--white);
  gap: 8px;
}

.watchlistContent {
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

// TOOLBAR

.watchlistToolBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  gap: 4px;
  background-color: var(--background-primary);
  padding: 8px;
  border-radius: 4px;
}

// SELECTED WATCHLIST TOOLBAR

.watchlistContentToolBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  height: 32px;
  gap: 8px;
}

// TABLES

.deleteTickerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteTickerButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  cursor: pointer;
}

.deleteTicker {
  width: 20px;
  height: 20px;
}

/// MODALS

.watchlistModal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.watchlistInputTitle {
  border-radius: 6px;
  border: 2px solid var(--light-grey-accent);
  background-color: transparent;
  padding: 6px 8px;
  font-size: var(--large-text);

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.watchlistInputDesc {
  border-radius: 6px;
  border: 2px solid var(--light-grey-accent);
  background-color: transparent;
  padding: 6px 8px;
  font-size: var(--large-text);
  height: 120px;
  max-height: 120px;
  min-height: 120px;

  &:focus {
    transition: all 0.3s ease-in;
    outline: none;
    border: 2px solid var(--primary-color);
  }
}

.addTickerModal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

/// TABS

.watchlistTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.watchlistTabList {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  height: fit-content;
  margin-bottom: 8px;
  width: 100%;
  border-bottom: 1px solid var(--grey-accent);
  align-items: center;
}

.watchlistTab {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  cursor: pointer;
  font-size: var(--default-text);
  user-select: none;
  white-space: nowrap;
  // border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.watchlistSelectedTab {
  background-color: var(--grey-accent);
}

.watchlistTabPanel {
  width: 100%;
}

.tabDivider {
  height: 18px;
  border: 0.5px solid var(--grey-accent);
  // background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.watchlistDisplayTab {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  cursor: pointer;
  font-size: var(--default-text);
  user-select: none;
  white-space: nowrap;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

/// Watchlist Tabs

.watchlistDisplayTabs {
  display: flex;
  align-items: center;
  width: 100%;
}

//// Error Note
.watchlistError {
  font-size: 12px;
  color: var(--red);
  margin-top: -4px;
}

.noSymbolContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  gap: 8px;
}

.insideWrapper {
  height: calc(100vh - 122px);
}

.noWatchlistsAvailable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 10%;
  flex-direction: column;
  gap: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
}

.closeModal {
  height: 24px;
  width: 24px;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.swapWatchlistDisplay {
  height: 24px;
  width: 30px;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.watchlistModalHeader {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.wrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: calc(100vh - 200px);
}

.watchlistContentToolBarButtons {
  display: flex;
  gap: 4px;
  align-items: center;
  width: fit-content;
  overflow-x: auto;
}

.watchlistNameTitle {
  font-size: 16px;
  font-weight: var(--bold-text);
  color: var(--white);
  white-space: nowrap;
}

.transactionTable {
  width: 100%;
  height: 100%;
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.basicNewsTableWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--background-primary);
  border-radius: 4px;
  gap: 8px;
}

.basicNewsTable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.newsTable {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // overflow-y: scroll;
  // gap: 8px;
  // border-radius: 4px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.newsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 5px 0;
  color: rgb(222, 222, 222);
  font-size: var(--default-text);
  border-bottom: 2px solid var(--background-secondary);
}

.symbol {
  width: 60px;
  padding-left: 4px;
}

.site {
  width: fit-content;
  white-space: nowrap;
  text-align: end;
}

.title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--white);
}

.date {
  width: fit-content;
  white-space: nowrap;
  text-align: start;
  padding-right: 4px;
}

.newsTableSearch {
  width: 100%;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.swap {
  width: 20px;
  height: 20px;
}

.swapWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 28px;
  border-radius: 4px;
  height: 28px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

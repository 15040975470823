.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 8px;
  user-select: none;
  padding-bottom: 8px;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.leftWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.rightWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

//// Unit change
.changeUnitWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
}

.unitButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  //   width: 28px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}

.divider {
  width: 1px;
  height: 60%;
  background-color: var(--grey-accent);
}

//// dataFrequency

.changeDataFrequencyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
}

.dataFrequencyButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  //   width: 28px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}

//// decimals
.decimals {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: 28px;
  font-size: var(--default-text);
  gap: 8px;
}

.decimalsButton {
  background-color: var(--background-primary);
  padding: 3px;
  height: 100%;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;

  &:active {
    transition: 0.2s ease;
    background-color: var(--background-secondary);
    color: var(--primary-color);
  }
}

.decimalsButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
}

.decimalsName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 26px;
}

.decimalsIcon {
  width: 26px;
  height: 16px;
  cursor: pointer;
}

//// Range
.rangeContainer {
  width: 100%;
  height: 48px;
  padding: 12px 30px;
  max-width: 1400px;
}

.sliderTrack {
  margin-top: 8px;
  height: 2px;
  background-color: var(--light-grey-accent);
  border-radius: 2px;
}

.sliderThumb {
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.sliderThumbDragging {
  width: 16px;
  height: 1px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: relative;
}

.sliderThumbDragValue {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--default-text);
  color: var(--white);
}

.sliderMark {
  position: absolute;
  height: 12px;
  width: 4px;
  background-color: var(--background-primary);
}

.markValue {
  position: absolute;
  top: 12px;
  transform: translateX(-6px);
  font-size: var(--default-text);
  color: var(--white);
}

.searchMetricsInput {
  max-width: 400px;
  width: 100%;
  min-width: 200px;
  height: 28px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.iconButton {
  height: 28px;
  padding: 0 8px;
  background-color: var(--grey-accent);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.CalendarWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  height: calc(100vh - 208px);
  overflow-y: auto;
}

.dateColumn {
  height: 100%;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1.5px solid var(--background-secondary);
  background-color: var(--background-secondary);
  border-radius: 2px;
  gap: 8px;
  font-size: var(--large-text);
  position: sticky;
  top: 0;
  cursor: pointer;

  &:hover {
    border: 1.5px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.notSelectableDate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1.5px solid var(--background-secondary);
  background-color: var(--background-secondary);
  border-radius: 2px;
  gap: 8px;
  font-size: var(--large-text);
  position: sticky;
  top: 0;
}

.today {
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  color: var(--white);
  padding: 2px 4px;
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.event {
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  // border: 1px solid var(--background-tertiary);
  border: 1px solid var(--grey-accent);

  font-size: var(--default-text);
}

.events {
  gap: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  border-radius: 4px;
}

.gridEvents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 8px;
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.searchInput {
  width: 100%;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 1px 8px;
  border-radius: 4px;
  outline: none;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

.fullScreen {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.selectedEventGrid {
  height: calc(100vh - 242px);
  display: grid;
  gap: 8px;
  overflow-y: auto;
}

.title {
  font-size: 20px;
  color: var(--white);
}

.gridEvent {
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  font-size: var(--default-text);
}

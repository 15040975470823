.corporateEvents {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.contentWrapper {
  display: flex;
  gap: 8px;
  height: var(--content-view-height);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

.geoRevSegmentBottomPanel::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.geoRevSegmentBottomPanel::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}

.geoRevSegmentBottomPanel::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

.geoRevSegmentChartWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  gap: 8px;
}

.geoRevSegment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 294px;
  background: var(--background-primary);
  border: transparent;
  border-radius: var(--border-radius);
}

.geoRevSegmentLeftPanel {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 212px;
}

.geoRevSegmentRightPanel {
  width: 220px;
  height: 212px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.geoRevSegmentRightPanelLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: var(--default-text);
  width: 100%;
}

.geoRevSegmentLeftPanelTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 320px;
  height: 72px;
  overflow-y: auto;
  overflow-x: hidden;
}

.geoRevSegmentBottomPanel {
  width: 100%;
  height: 72px;
  overflow-y: auto;
}

.geoRevSegmentLeftPanelBarChart {
  width: 100%;
  height: 212px;
}

// Custom Legend

.legendList {
  display: block;
  width: 320px;
  column-count: 2;
  list-style-type: square;
  height: fit-content;
  margin: 0;
}

.legendItem {
  font-size: var(--default-text);
  color: var(--white);
}

.dashboardContent {
  display: 'flex';
  flex-direction: 'column';
  justify-content: space-between;
  width: 100%;
}

.logoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 8px 12px 8px 16px;
  gap: 16px;
  margin-bottom: 6px;
}

.outseekLogo {
  width: 124px;
}

.subscriptBlueText {
  font-size: 12px;
  color: var(--white);
  background-color: var(--primary-color);
  padding: 2px 4px;
  border-radius: 4px;
}

.swapIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;

  user-select: none;
  &:hover {
    fill: var(--button-hover);
    transition: ease 0.1s;
  }
}

.ToolMenuContentWrapper {
  max-height: calc(100vh - 182px);
  overflow-y: auto;
  overflow-x: hidden;
}

.pricingWrapper {
  color: var(--white);
  height: var(--view-height);
  overflow-y: auto;
}

.pricingTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px;
}

.financialStatementsViewWrapper {
  height: var(--view-height);
  width: 100%;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.financialStatementsContentWrapper {
  height: var(--content-view-height);
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  color: var(--white);
  display: flex;
}

.stankeyWrapper {
  width: 50%;
}

.stankeyAssetsWrapper {
  width: 50%;
}

//// tabs ////

.financialStatementsTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.financialStatementsTabList {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  height: 28px;
}

.financialStatementsTab {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  font-weight: var(--bold-text);
  background-color: var(--background-primary);
  cursor: pointer;
  font-size: var(--default-text);
  user-select: none;
  white-space: nowrap;
  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
    transition: 0.3s ease-out;
  }
}

.financialStatementsSelectedTab {
  background-color: var(--grey-accent);
  // color: var(--primary-color);
}

.financialStatementsTabPanel {
  width: 100%;
}

.chartshit {
  // margin-top: 30px;
  height: calc(100vh - 150px);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
}

.divider {
  width: 1px;
  height: 60%;
  background-color: var(--grey-accent);
}

.tabDivider {
  height: 20px;
  width: 1px;
  background-color: var(--grey-accent);
}

.tabSelectedDivider {
  height: 20px;
  width: 1px;
  background-color: transparent;
}

.changeChartTypeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--grey-accent);
  height: 28px;
}

.chartTypeButton {
  background-color: var(--background-primary);
  padding: 4px 8px;
  font-size: var(--default-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  white-space: nowrap;
}

.chartNotAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  width: 100%;
  font-size: var(--default-text);
  font-weight: var(--bold-text);
  color: var(--white);
  padding: 20px;
}

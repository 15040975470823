.addTickerModal {
  //////
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

//// Error Note
.duplicateTickerError {
  font-size: 12px;
  color: var(--red);
  margin-top: -4px;
  margin-left: 4px;
}

.closeModal {
  height: 24px;
  width: 24px;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.watchlistModalHeader {
  //////
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.modalConfirmButton {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}

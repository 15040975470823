.upperDecker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: end;
  gap: 12px;
  padding-right: 8px;
  align-items: center;
  height: 38px;
  width: 100%;
  background-color: var(--background-primary);
  color: var(--white);
}

.buttonWrappers {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  justify-content: end;
  gap: 6px;
  padding-right: 8px;
  align-items: center;
  height: 38px;
  width: fit-content;
  background-color: var(--background-primary);
  color: var(--white);
}

.focusDiv {
  width: fit-content;
  height: 28px;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: var(--white);
  font-size: 12px;
  gap: 4px;
  padding: 0 6px;
  &:hover {
    background-color: var(--button-hover);
  }
}

.focusSpan {
  width: fit-content;
  height: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.focusButton {
  width: 22px;
  height: 22px;
  border-radius: var(--border-radius);
}

.reportButton {
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
  background-color: var(--grey-accent);
  font-size: var(--default-text);
  border: none;
  color: var(--white);
}

.dashboardLabel {
  width: fit-content;
  height: 28px;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: var(--white);
  font-size: var(--large-text);
  gap: 4px;
  padding: 0 6px;
}

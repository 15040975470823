.dashboardWrapper {
  width: 560px;
  height: fit-content;
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  padding: 12px;
  gap: 8px;
}

.buttonWrapper {
  gap: 12px;
}

.titleMyDashboards {
  width: 160px;
  height: 24px;
  font-style: normal;
  font-weight: var(--bold-text);
  font-size: 17.19px;
  color: var(--white);
}

.modalCloseButton:hover {
  cursor: pointer;
  background-color: rgba(215, 215, 215, 0.15);
}

.modalCloseButton {
  height: 24px;
  width: 24px;
  border-radius: var(--border-radius);
}

.myDashboardBottomPanel {
  height: 28px;
}

.buttonWrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.headerTitle {
  display: flex;
  flex-direction: row;
  color: var(--white);
  font-style: normal;
  font-weight: var(--bold-text);
  font-size: var(--large-text);
  height: 28px;
}

.test {
  height: 100%;
  width: 266px;
  padding-left: 8px;
}

.renameButton:hover {
  opacity: 80%;
}

.renameText {
  margin: 0px;
  color: var(--white);
  padding-left: 12px;
}

.deleteButton {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 0px 8px;
  border-radius: var(--border-radius);
  margin-right: 8px;
  height: 24px;
}
.deleteButton:hover {
  background-color: rgba(215, 215, 215, 0.15);
}
.deleteText {
  margin: 0px;
  color: var(--red);
  font-size: var(--large-text);
}

.myDashboardsTopPanel {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}

.topPanelTitle {
  height: 100%;
  display: flex;
  align-content: center;
}

.titleMyDashboards {
  font-size: 17.19px;
}
.editIconBackground {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}

.editIcon {
  width: 18px;
  height: 18px;
}

.editIconBackground:hover {
  background-color: rgba(215, 215, 215, 0.15);
}

.entryContainer {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  background: var(--background-tertiary);
  width: 100%;
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-bottom: 8px;
  height: 36px;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.entryContainer:hover {
  background-color: var(--background-secondary);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.dashboardEntryContainer {
  height: fit-content;
  width: 100%;
  background: 'var(--background-primary)';
}

.myDashboardsMiddlePanel {
}

.entryText {
  font-style: normal;
  font-weight: 400;
  font-size: var(--large-text);
  color: var(--white);
}

.entryNamePanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 260px;
}

.entryName {
  max-width: 260px;
  font-weight: 400;
  color: var(--white);
  padding: 0 12px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--large-text);
}

.entryDatePanel {
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.entryEditPanel {
  width: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.dateLastUpdated {
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  width: 100%;
  color: var(--white);
  padding: 0 8px;
  font-size: var(--large-text);
}

.itemContainer::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.itemContainer::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}
.itemContainer::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}

.newDashboardBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 8px;
  width: fit-content;
  height: 28px;
  border-radius: var(--border-radius);
  border: transparent;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  color: var(--white);
  background-color: var(--primary-color);

  &:hover {
    transition: all 0.2s ease-in;
    background: rgba(55, 152, 249, 0.7);
    color: var(--white);
  }
}

.editIcons {
  cursor: pointer;
}

.contact-form-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--outseek-background);
  flex-direction: column;
  padding: 100px;
}

.contact-form {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  // align-items: center;
  background-color: var(--outseek-background);
  flex-direction: column;
}

.contact-form-subtitle {
  color: var(--white);
  font-size: 16px;
  margin-bottom: 8px;
}

.contact-form-title {
  font-size: 24px;
  color: var(--white);
  margin-bottom: 6px;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#name,
#email {
  width: 500px;
  height: 44px;
  padding: 8px;
  font-size: 15px;
  border: 2px solid var(--grey-accent);
  border-radius: 4px;
  color: var(--white);
  margin-bottom: 12px;
  background-color: var(--background-primary);
  &:active {
    border-color: var(--primary-color);
  }
}

#message {
  width: 500px;
  height: 300px;
  padding: 8px;
  font-size: 15px;
  border: 2px solid var(--grey-accent);
  border-radius: 4px;
  color: var(--white);
  margin-bottom: 12px;
  background-color: var(--background-primary);
  &:active {
    border-color: var(--primary-color);
  }
}

@media (max-width: 768px) {
  .contact-form-wrapper {
    padding: 50px;
  }

  #message,
  #name,
  #email {
    width: 400px;
  }
}

@media (max-width: 480px) {
  .contact-form {
    width: 100%;
  }

  .contact-form-title {
    font-size: 20px;
  }

  .contact-form-subtitle {
    font-size: 14px;
  }

  #message {
    width: 100%;
    height: 200px;
    font-size: 14px;
  }
  #name,
  #email {
    width: 100%;
    height: 44px;
    font-size: 14px;
  }
}

.indexChartsWrapper {
  width: 100%;
  height: 100%;
  gap: 8px;
  display: flex;
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

.indexChartWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
  gap: 8px;
}

.indexChartTitle {
  display: flex;
  color: var(--white);
  justify-content: space-between;
  height: fit-content;
  align-items: center;
}

.indexChartTitleText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white);
  font-weight: var(--bold-text);
  font-size: var(--large-text);
}

.indexChart {
  height: calc(100% - 30px);
}

.indexChartTimeRangeWrapper {
  background-color: var(--background-primary);
  border-radius: 4px;
}

.indexSelector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  width: 100%;
}

.indexSelectorWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  min-width: 300px;
  height: 100%;
}

.index {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 6px;
  border-radius: 4px;
  background-color: var(--background-secondary);
  color: var(--white);
  font-size: var(--large-text);
  cursor: pointer;
  box-sizing: border-box;
  width: 290px;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 308px);
}

.indexValue {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--default-text);
  font-weight: var(--bold-text);
}

.title {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchInput {
  width: 100%;
  height: 32px;
  min-height: 32px;
  border: 2px solid var(--grey-accent);
  background-color: var(--background-primary);
  color: var(--white);
  font-size: var(--large-text);
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;
  position: relative;

  &:focus {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
  &:active {
    border: 2px solid var(--primary-color);
    transition: 0.2s ease;
  }
}

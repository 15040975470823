.toggleButtonGroup {
  position: relative;
  display: flex;
  border-radius: 10px;
  background-color: var(--background-primary);
  overflow: hidden;
  height: 50px;
  width: 200px;
  font-weight: var(--bold-text);
  border: 1px solid var(--background-tertiary);
  z-index: 2;
  user-select: none;
}

.togglePill {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 50%;
  background-color: var(--background-tertiary);
  border-radius: 6px;
  transition: all 0.3s ease;
  z-index: 1;
}

.toggleButton {
  flex: 1;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--white);
  transition: color 0.3s ease;
}

.isYearly .togglePill {
  left: 50%;
  right: 4px;
}

.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.discountMessage {
  position: absolute;
  left: 210px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: var(--bold-text);
  color: var(--green);
  border-radius: 6px;
  width: fit-content;
  z-index: 1;
}

.switchWrapperTable {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.discountMessageTable {
  display: flex;
  align-items: center;
  font-weight: var(--bold-text);
  color: var(--green);
  border-radius: 6px;
  width: fit-content;
  z-index: 1;
}

@media (max-width: 1100px) {
  .discountMessage {
    position: static;
    // left: 210px;
    display: flex;
    align-items: center;
    font-weight: var(--bold-text);
    color: var(--green);
    border-radius: 6px;
    width: fit-content;
    z-index: 1;
  }

  .switchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
  }
}

.checksTabs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tabButton {
  cursor: pointer;
  padding: 0 6px;
  text-align: center;
  user-select: none;
}

.tabWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.divider {
  width: 1px;
  height: 20px;
  background-color: var(--light-grey-accent);
}

.checkTabContentWrapper {
  overflow: auto;
}

.checkTabContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

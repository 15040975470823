.corporateEvents {
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.contentWrapper {
  display: flex;
  gap: 8px;
  height: var(--content-view-height);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
}

.eventsLinks {
  display: flex;
  gap: 12px;
  height: 48px;
  background-color: var(--background-primary);
  padding: 8px 12px;
  align-items: center;
}

.navLink {
  display: flex;
  gap: 12px;
  height: 100%;
  align-items: center;
}

.divider {
  height: 80%;
  background-color: var(--grey-accent);
  width: 1px;
}

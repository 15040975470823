.wavyButton {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: none;
  outline: none;
  font-size: var(--large-text);
  color: var(--white);
  background-color: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  font-weight: var(--bold-text);
  letter-spacing: 0.2px;
}

.wavyButton:before {
  content: '';
  background: linear-gradient(
    45deg,
    var(--primary-color),
    var(--background-primary),
    var(--white),
    var(--background-primary),
    var(--primary-color)
  );
  position: absolute;
  background-size: 400%;
  z-index: -1;
  filter: blur(1px);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  animation: glowing 40s linear infinite;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
  border-radius: 8px;
}

.wavyButton:hover:before {
  opacity: 1;
}

.wavyButton:active {
  color: var(--white);
}

.wavyButton:active:after {
  background: transparent;
}

.wavyButton:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  opacity: 0.7;
  left: 0;
  top: 0;
  border-radius: 8px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 500% 0;
  }
  100% {
    background-position: 0 0;
  }
}

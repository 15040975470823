.splitEventWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.eventDetails {
  display: flex;
  gap: 4px;
  align-items: center;
  .info {
    font-size: var(--default-text);
  }
  .value {
    font-size: 16px;
    font-weight: bold;
  }
}

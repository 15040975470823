.tab {
  width: 100%;
  height: 240px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-top: none;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.metricCatalogInput {
  color: var(--white);
  background: transparent;
  padding: 8px;
  color: var(--white);
  width: 100%;
  font-size: var(--default-text);
  border: 1px solid grey;
  border-top: none;
  border-bottom: none;

  &:focus {
    outline: none;
  }
}

.comparisonChartsMetricCatalogContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: var(--background-primary);
  border-radius: var(--border-radius);
}

.metricCatalogContainerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 0px;
  color: var(--white);
  width: 100%;
  height: 30px !important;
  cursor: pointer;
  background-color: var(--primary-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  user-select: none;
}

.metricCatalogCategoryListItem {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white);
  border-radius: 4px;
  gap: 6px;

  &:hover {
    background-color: rgba(215, 215, 215, 0.1);
    cursor: pointer;
  }
}

.metricCatalogCategoryListWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  padding: 8px 0;
}

.tabList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 28px;
  position: sticky;
  top: 0;
  background: var(--background-primary);
}

.catalogTabs {
  height: 28px;
  width: fit-content;
  white-space: nowrap;
  padding: 0 8px;
  color: var(--off-white);
  list-style-type: none;
  cursor: pointer;
  font-size: var(--default-text);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-primary) !important;
  border: none !important;
  white-space: nowrap;
  user-select: none;
}

.tabDivider {
  height: 20px;
  width: 1px;
  background-color: var(--grey-accent);
}

.selectedTab {
  height: 28px;
  width: fit-content;
  white-space: nowrap;
  padding: 0 8px;
  list-style-type: none;
  cursor: pointer;
  font-size: var(--default-text);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-primary) !important;
  border: none !important;
  white-space: nowrap;
  color: var(--primary-color);
}

.metricCatalogExpandArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--large-text);
}

.rangeThumb {
  position: absolute;
  width: 6.26px;
  height: 13.84px;
  left: 461.92px;
  top: 134.9px;
  background: var(--white);
  border-radius: var(--border-radius);
}

.esgScoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 180px;
  border-radius: var(--border-radius);
}

.esgScoreContainerChartPanel1 {
  width: 260px;
  height: 100%;
}

.esgScoreContainerChartPanel2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  padding: 8px 0;
  gap: 12px;
  width: fit-content;
  height: 100%;
}

.esgScoreContainerChartPanel3 {
  width: 190px;
  height: 100%;
}

.esgScoreWrapperLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 180px;
  padding: 0px;
  width: fit-content;
}

.esgScoreWrapperRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: fit-content;
}

.esgScoreWrapperTitle {
  width: '100%';
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 17.19px;
  line-height: 17px;
  color: var(--white);
}

.esgScoreWrapperBigNumber {
  width: fit-content;
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  font-size: 60px;
  color: var(--white);
}

.esgScoreWrapperBottomMessage {
  width: 132px;
  height: fit-content;
  line-height: 14px;
  font-weight: 400;
  font-size: var(--default-text);
  color: var(--white);
}

.esgPieChartLabel {
  position: absolute;
  color: var(--white);
  font-size: var(--default-text);
}

.scoreWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: fit-content;
  height: 100%;
}

.valueWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: fit-content;
  height: fit-content;
}

.sScoreValueText {
  color: rgba(126, 176, 213, 0.6);
  font-size: 17.19px;
  font-weight: var(--bold-text);
}

.eScoreValueText {
  color: rgba(178, 224, 97, 0.6);
  font-size: 17.19px;
  font-weight: var(--bold-text);
}

.gScoreValueText {
  color: rgba(253, 127, 111, 0.6);
  font-size: 17.19px;
  font-weight: var(--bold-text);
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
  font-size: 15px;
  width: fit-content;
  height: fit-content;
  color: var(--white);
}

.esgIcons {
  width: 20px;
  height: 20px;
}

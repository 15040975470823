.InstStockOwn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  background: var(--background-primary);
  border-radius: var(--border-radius);
}

.InstStockOwnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: fit-content;
}

.InstStockOwnRow1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: 124px;
}
.InstStockOwnRow2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: 96px;
}

.InstStockOwnRow3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: fit-content;
  height: 96px;
}
.InstStockOwnRow1Item {
  width: 246px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: var(--background-secondary);
  border-radius: var(--border-radius);
}

.InstStockOwnRow2Item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px;
  width: 186px;
  height: 100px;

  background: var(--background-secondary);
  border-radius: var(--border-radius);
}

.labeldiv {
  justify-content: center;
  width: 100%;
  height: 13px;
}

.label {
  color: var(--off-white);
  font-size: var(--default-text);
  padding: 0;
  margin: 0;
}

.companyProfileWrapper {
  width: 100%;
  height: var(--view-height);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.companyProfileContentWrapper {
  width: 100%;
  height: var(--content-view-height);
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  color: var(--white);
  font-size: var(--default-text);
}

.descWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.profileRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 176px;
}

.descRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: calc((100% - 176px - 16px) / 2);
}

.headQuarterWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.companyOverviewChartTimeWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.dataRowWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timeRange {
  position: absolute;
  margin-top: 12px;
  z-index: 2;
}

.dataRow {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  justify-content: space-between;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.identifierWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--light-grey-accent);
  white-space: nowrap;
}

.value {
  font-weight: var(--bold-text);
}

.divider {
  width: 100%;
  height: 2px;
  background-color: var(--background-secondary);
}

.noDataWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noData {
  font-size: var(--default-text);
  color: var(--light-grey-accent);
}

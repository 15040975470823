.heatMap {
  width: 100%;
  height: 100%;
  background-color: var(--background-primary);
  border-radius: 4px;
}

.chartWrapper {
  width: 100%;
  height: calc(100% - 20px);
  margin-left: -8px;
}

.chartView {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding: 8px;
  padding-bottom: 0;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
  overflow-x: auto;
  padding-right: 8px;
}

.heatmapText {
  font-size: var(--default-text);
  color: var(--white);
  white-space: nowrap;
}

.backButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 26px;
  padding: 0 8px;
  font-size: var(--default-text);
  color: var(--white);
  cursor: pointer;
  border-radius: 2px;
  background-color: var(--grey-accent);
}

.topWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: var(--background-secondary);
  border-radius: 4px;
  border: 1.5px solid var(--grey-accent);
}

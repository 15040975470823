.scoreCardWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--background-tertiary);
  width: fit-content;
  height: fit-content;
}

.scoreCardName {
  font-size: var(--large-text);
  color: var(--white);
  white-space: nowrap;
}

.scoreCardScore {
  font-size: 28px;
  font-weight: var(--bold-text);
  color: var(--white);
  line-height: 30px;
}

.earningsWrapper {
  background-color: var(--background-primary);
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.earningsTableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: var(--white);
  padding: 8px;
  padding-top: 4px;
}

.earningsTableNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
  font-size: var(--default-text);
  color: var(--white);
}

.calendar {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  gap: 4px;
  width: 100%;
}

.calendarWrapper {
  display: flex;
  gap: 4px;
  width: 100%;
  padding-bottom: 8px;
}

.earningsDetails {
  font-size: var(--default-text);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.dateWrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: var(--white);
  border: 1.5px solid var(--grey-accent);
  padding: 2px 8px;
  border-radius: 6px;
  width: 70px;
}

.date {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.numberDate {
  font-size: 14px;
  line-height: 18px;
}

.dayOfWeek {
  display: flex;
  font-size: 11px;
  align-items: center;
}

.earningsCount {
  display: flex;
  flex-direction: column;
}

.earningsDetailsHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-secondary);
  padding: 4px 8px;
  border-radius: 4px;
  gap: 8px;
}

.earningsColumnHeader {
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

.contentWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.contentRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 5.25px 8px;
  border-bottom: 2px solid var(--background-secondary);
  white-space: nowrap;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.dot {
  background-color: var(--white);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 4px;
}

.earningsColumn {
  width: 100%;
}

.noEarningsData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: var(--default-text);
  color: var(--light-grey-accent);
}

.arrowShift {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 2px;
  cursor: pointer;
}

.earningsTitleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--default-text);
  padding-bottom: 4px;
  font-weight: var(--bold-text);
  gap: 8px;
  justify-content: space-between;
  white-space: nowrap;
}

.todayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.titleText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

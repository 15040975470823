.tableTrendTypeBWidget {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--background-primary);
  border-radius: 4px;
}

.tableTrendTypeBWidgetChartPanel {
  display: flex;
  width: calc(100% - 480px);

  height: 100%;
  flex-direction: column;
  border-radius: 4px;
}

.tableTrendTypeBSelectableTableWrapper {
  height: 100%;
  width: 480px;
}

.tableTrendTypeBWidgetChartWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 8px;
}

.tableTrendTypeBWidgetChartPills {
  display: flex;
  gap: 4px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #666a6f;
  border-radius: 3px;
}

::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 4px;
}

.tableTrendTypeBChartTimeRangeWrapper {
  padding-top: 0;
  background-color: var(--background-primary);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
}

.tableTrendTypeBWidgetChartPanelChartAndPillWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  gap: 4px;
  background-color: var(--background-primary);
}

.tableTrendTypeBWidgetChartPanelTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageStripe {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  background-color: var(--background-primary);
  padding: 10px;
  border-radius: 4px;
  gap: 8px;
  font-size: var(--large-text);
}

.manageStripeButton {
  width: 100%;
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 4px;
  padding: 6px 8px;
  cursor: pointer;
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  width: fit-content;
}

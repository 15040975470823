$proPlan: var(--background-primary);

.pricingTableWrapper {
  width: 100%;
  padding: 150px;
  overflow-x: auto;
}

.pricingTable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: var(--white);
  background-color: transparent;
  max-width: 1800px;

  thead th {
    background-color: var(--outseek-background);
    position: sticky;
    top: 68px;
    z-index: 10;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    padding: 12px 20px;
  }

  th {
    text-align: center;
    font-weight: var(--bold-text);
  }

  .sectionHeader {
    font-weight: var(--bold-text);
    border-radius: 8px;
    font-size: 20px;
    border-bottom: 2px solid var(--background-primary);
  }

  .tableData {
    text-align: center;
    width: 25%;
  }

  .planHeader {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .planName {
    font-weight: var(--bold-text);
    font-size: 24px;
  }

  .planPrice {
    font-weight: var(--bold-text);
    font-size: 18px;
    color: var(--light-grey-accent);
  }

  .proHeader {
    background-color: $proPlan;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .proData {
    background-color: $proPlan;
  }

  .proSectionHeader {
    background-color: $proPlan;
    border-bottom: 2px solid var(--background-secondary);
  }
  .tableDataLabel {
    color: var(--light-grey-accent);
  }
}

.pricingTable tbody tr:last-child td:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pricingTable tbody tr:last-of-type td:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 1100px) {
  .sectionHeader {
    border-bottom: none !important;
    font-size: 18px;
  }
  .row {
    border-bottom: 1px solid var(--background-secondary);
  }
  .tableDataLabel {
    text-align: center;
    padding: 0px 12px !important;
    padding-top: 8px !important;
  }

  .tableDataTablet {
    text-align: center;
    border-radius: 10px;
    background-color: var(--background-primary);
  }
  .tableData {
    width: 33.33%;
  }
}

.primeButton {
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  font-size: 15px;
  font-weight: var(--bold-text);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300%;
    height: 300%;
    background: linear-gradient(
      45deg,
      var(--primary-color),
      var(--purple),
      var(--primary-color),
      var(--background-primary),
      var(--primary-color),
      var(--purple)
    );
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    animation: glowing 15s linear infinite;
    opacity: 1;
    border-radius: 4px;
  }

  &:active {
    color: var(--white);

    &:after {
      background: transparent;
    }
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-color);
    opacity: 0.7;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
}

.getStartedButton {
  text-align: center;
  background-color: var(--primary-color);
  border: none;
  color: var(--white);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: var(--bold-text);
  width: fit-content;
}

.backgroundWrapper {
  background-color: var(--outseek-background);
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 80px 15% 10px 15%;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 20px;
  font-weight: var(--bold-text);
  color: var(--white);
  margin-bottom: 10px;
  margin-left: 20px;
  width: 100%;
}

.text {
  font-size: 14px;
  color: var(--white);
  width: 100%;
  margin-bottom: 20px;
}

.title {
  font-size: 36px;
  font-weight: var(--bold-text);
  color: var(--primary-color);
  width: 100%;
  text-align: center;
  line-height: 46px;
}

.subTitle {
  font-size: 11px;
  color: var(--grey-accent);
  width: 100%;
  text-align: center;

  &:hover {
    color: var(--grey-accent);
  }
}

.lastUpdated {
  font-size: 14px;
  color: var(--light-grey-accent);
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.TOS_PP {
  p {
    font-size: 15px;
    color: var(--white);
    margin: 20px 50px;
  }
  h1 {
    font-size: 20px;
    font-weight: var(--bold-text);
    color: var(--white);
    margin-bottom: 10px;
  }
  .listItemNoIndent {
    padding-left: 0;
  }
  h5 {
    font-size: 16px;
  }
  .listItem {
    padding-left: 0;
    font-size: 15px;
    color: var(--white);
    margin: 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .backgroundWrapper {
    padding: 80px 5% 10px 5%;
  }
  .termsOfService {
    p {
      margin: 20px 20px;
    }
  }
}

.prompts {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.examplePrompts {
  display: grid;
  gap: 12px;
  padding: 0 18px;
  width: 100%;
  max-width: 850px;
  font-size: var(--large-text);
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  .examplePrompt {
    padding: 12px;
    border: 1.5px solid var(--background-tertiary);
    background-color: transparent;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--light-grey-accent);

    &:hover {
      background-color: var(--background-secondary);
      border: 1.5px solid var(--primary-color);
    }
  }
}

.logo {
  user-select: none;
  font-size: 40px;
  font-weight: 600;
  background: linear-gradient(
    90deg,
    var(--primary-color),
    var(--secondary-color),
    var(--primary-color)
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientText 20s linear infinite;
}

@keyframes gradientText {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 300% 50%;
  }
}

:root {
  --button-hover: rgba(215, 215, 215, 0.1);
  --border-radius: 4px;
  --button-transition: 0.1s ease;
  --button-padding: 4px 8px;

  --bold-text: 500;
  --default-text: 13px;
  --large-text: 14px;

  // ⭐ Dark Color Scheme
  --outseek-background: #121212;
  --background-primary: #202122;
  --background-secondary: #2d2d2d;
  --background-tertiary: #3d3d3d;
  // --primary-color: #337eca;
  --primary-color: #4079a8;

  /* Light Color Scheme */

  --primary-yellow: #adab21;
  // --primary-color: #1f6bb8;
  // --primary-color: #005da6;

  // ⭐ Grapahana Color Scheme
  // --outseek-background: #0f0f0f;
  // --background-primary: #181b1f;
  // --background-secondary: #1e2327;
  // --background-tertiary: #2b3844;
  // --primary-color: #3798f9;

  // ⭐ Outseek OG Color Scheme
  // --outseek-background: #0d1117;
  // --background-primary: var(-background-primary);
  // --background-secondary: #232c35;
  // --background-tertiary: #2b3844;
  // --primary-color: #3798F9;

  // ⭐ General colors
  --grey-accent: #696969;
  // --light-grey-accent: #8b8b8b;
  --light-grey-accent: #b3b3b3;

  --white: #fff;
  --off-white: #d7d7d7;

  --green: #21ad3d;
  --red: #f84f31;
  --yellow: #fff466;
  --purple: #8884d8;
  // ⭐ Fonts
  --font-family: 'Roboto Regular', 'Arial Regular', Helvetica, Verdana,
    'Segoe UI Regular';

  // View Heights

  --view-height: calc(100vh - 54px);
  --content-view-height: calc(100vh - 110px);
}

*::-webkit-scrollbar-corner {
  background: transparent;
}
a {
  text-decoration: none !important;
  &:hover {
    color: var(--primary-color) !important;
  }
}
.body {
}

html {
  text-rendering: optimizeLegibility;
  background-color: var(--outseek-background);
}

// default scrollbar styles

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
}

:root {
  color-scheme: dark;
}

.dashboard {
  background-color: var(--background-primary);
  overflow: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: var(--white) !important;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.table-row-line {
  width: 100%;
  height: 1px;
  background-color: var(--white);
}

.table-row {
  border-bottom: 2px solid var(--background-secondary);
  box-sizing: border-box;

  &:hover {
    transition: var(--button-transition);
    background-color: var(--background-secondary);
  }
}

.table-cell {
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    // background-color: var(--background-tertiary);
    // border-top: 2px solid var(--background-tertiary);
    // border-bottom: 2px solid var(--background-tertiary);
  }
}

.table-cell-no-hover {
  line-height: 28px;
}

.td:nth-child(2) {
  background: '#CCC';
}

::placeholder {
  color: rgba(215, 215, 215, 0.7);
}

.grid-item {
  background: transparent;
  height: fit-content;
  border-radius: 5px;
  display: flex;

  flex-direction: column;
}

.type-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  padding: 3px 0;
}
.type-item:hover {
  color: var(--white);
}

.type-item.selected {
  color: var(--white);
}

.data-edit__error {
  color: rgba(255, 0, 0, 0.7);
  font-size: var(--default-text);
  padding: 3px 0;
}

.detail__info {
  color: var(--white);
  padding: 20px;
}

.detail__edit {
  padding: 20px;
}

.detail__info > div {
  padding: 5px 0;
}

label {
  color: var(--white);
  display: block;
}

.dropdown > .btn-default,
.dropdown.open > .btn-default {
  background: #171819;
  border: 1px solid #141414;
  color: var(--white);
}

.dropdown > .btn-default:hover,
.dropdown > .btn-default:focus .dropdown.open > .btn-default:hover,
.dropdown.open > .btn-default:focus {
  background: #171819;
  border: 1px solid #141414;
  color: var(--white);
  outline: none;
}

.dropdown-menu {
  border: 1px solid #141414;
  background: #171819;
  color: var(--white);
}

.dropdown-menu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.dropdown-menu > li > a:hover {
  background: #171819;
  color: var(--white);
}

.react-grid-layout {
  background-color: var(--outseek-background);
  height: 100%;
}

.react-grid-item {
  z-index: 0;
}
.react-grid-item > .react-resizable-handle {
  margin-bottom: -15px;
}
.react-grid-item > .react-resizable-handle::after {
  top: 0;
  border-right: 2px solid white !important;
  border-bottom: 2px solid white !important;
}

.modal-body {
  padding: 0;
}

.data-edit {
  position: relative;
}

.data-edit__update {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.detail-page__title,
.edit-page__title {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  color: var(--white);
  text-align: center;
}

.go-back {
  position: fixed;
  right: 20px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  top: 20px;
  z-index: 10;
}

.go-back:hover {
  color: var(--white);
  cursor: pointer;
}

/// typed cursor
.typed-cursor {
  height: 0px;
  display: none;
}

.typed-cursor--blink {
  opacity: 0;
  display: none;
}

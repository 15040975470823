.accountPage {
  width: 100%;
  height: var(--view-height);
  background: var(--outseek-background);
  display: flex;
  color: var(--white);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.title {
  font-size: 28px;
}

.confirmModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  width: 400px;
  background-color: var(--background-primary);
  border: 2px solid var(--background-tertiary);
  padding: 20px;
  border-radius: 4px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
}

.confirmModalHeader {
  font-size: 15px !important;
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.singleValueWrapper {
  display: flex;
  flex-direction: column;
}

.singleValueSymbol {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
  line-height: 18px;
}

.singleValueName {
  font-size: 12px;
  color: var(--light-gray-accent);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}

.searchIconWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 4px 4px;
  background-color: transparent;
}

.analyticsView {
  display: flex;
  flex-direction: column;
  height: var(--view-height);
  width: 100%;
  gap: 8px;
  border-radius: 4px;
  color: var(--white);
}

.analyticsContentWrapper {
  display: flex;
  flex-direction: column;
  height: var(--content-view-height);
  width: 100%;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  font-size: var(--default-text);
  background-color: var(--background-primary);
}

.pills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  gap: 6px;
}

.pillsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 6px;
  height: 32px;
  overflow-x: auto;
}

.overviewWrapper {
  height: fit-content;
  width: 100%;
  display: flex;
  gap: 8px;
}

.contentWrapper {
  height: calc(100vh - 210px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  // padding: 8px;
  border-radius: 4px;
  font-size: var(--default-text);
  // border: 1px solid var(--background-tertiary);
  width: 100%;
}

.pillsTitle {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.customTooltip {
  font-size: 13px;
  font-weight: var(--bold-text);
  background-color: rgba(33, 33, 33, 0.9);
  border: 1px solid rgba(150, 150, 150);
  width: fit-content;
  border-radius: 4px;
  padding: 8px;
}

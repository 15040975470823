.Ticker {
  font-size: var(--large-text);
  font-weight: 500;
}

.ticker {
  cursor: pointer;
  transition: 0.4s ease-in-out;
  color: var(--primary-color);
  &:hover {
    transition: 0.4s ease-in-out;
    text-decoration: underline;
  }
}

.CompanyName {
  font-size: var(--large-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.NameFlagWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.stockPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  gap: 2px;
}

.NamePriceFlagWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 400px;
}

.ChartTableWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.metricsTable {
  display: flex;
  flex-direction: column;
  width: 105%;
}

.metricsTableLabel {
  font-size: 12px;
  color: var(--off-white);
  white-space: nowrap;
}

.metricsTableValue {
  font-size: 12px;
  font-weight: var(--bold-text);
  color: var(--white);
  white-space: nowrap;
}

.metricsTableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
}

.PriceText {
  font-size: 18px;
  font-weight: var(--bold-text);
  color: var(--white);
  padding-right: 4px;
}

.ChangeText {
  font-size: var(--large-text);
  font-weight: var(--bold-text);
  color: var(--white);
}

.exchangeText {
  font-size: 12px;
  color: var(--white);
  font-weight: 400;
}

.TickerDivider {
  height: 102%;
  width: 1px;
  border-radius: 6px;
  border: 0.5px solid var(--off-white);
}

.NameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 18px;
  gap: 6px;
  width: 100%;
  max-width: 330px;
}

.CompanyLogo {
  height: 18px;
  width: 18px;
  pointer-events: none;
}

.ExchangeSector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  height: 18px;
  gap: 6px;
}

.CurrencyFlag {
  height: 20px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.noChartDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
  color: var(--text-primary);
}

.spinnerWrapper {
  width: 400px;
  height: 174px;
}

.selectDiv {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary);
  border-radius: 4px;
  padding: 8px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--white);
}

.errorText {
  font-size: 12px;
  color: var(--red);
  margin-top: -4px;
  margin-left: 4px;
}

.modalConfirmButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.closeModal {
  height: 24px;
  width: 24px;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--button-hover);
  }
}

.watchlistModalHeader {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.successText {
  font-size: 12px;
  color: var(--green);
  margin-top: -4px;
  margin-left: 4px;
}

.addToWatchlist {
  width: 100%;
  margin-bottom: 4px;
}

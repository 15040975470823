.returns {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 4px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.returnsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  // height: calc(100% - 21px);
  height: 100%;
}

.returnsTitle {
  font-size: 12px;
  line-height: 14px;
}

.returnsValue {
  font-size: 12px;
  line-height: 16px;
}

.returnsWrapperTitle {
  color: var(--white);
  font-size: var(--default-text);
  margin-bottom: 2px;
}

.returnsItem {
  width: 100%;
  height: 100%;
}
